import { React } from 'react';
import {BrowserRouter, Route, Switch} from 'react-router-dom';

import Layout from './Layout.js';
import ScrollToTop from './ScrollToTop.js';

//Importamos nuestros componentes personalizados
import Inicio from '../../paginas/principales/Inicio.js';
import CrearCuenta from '../../paginas/principales/CrearCuenta.js';
import IniciarSesion from '../../paginas/principales/IniciarSesion.js';
import RecuperarCuenta from '../../paginas/principales/RecuperarCuenta.js';

import BuscarCuenta from '../../paginas/BuscarCuenta.js';
import PerfilExterno from '../../paginas/PerfilExterno.js';

import AcercaDe from '../../paginas/principales/AcercaDe.js';
import Privacidad from '../../paginas/principales/Privacidad.js';
import FortinTech from '../../paginas/principales/FortinTech.js';
import Adolfo from '../../paginas/principales/Adolfo.js';
import Armando from '../../paginas/principales/Armando.js';

// import Badges from '../../paginas/Badges';
// <Route exact path="/universidades" component={Badges}/>

import NotFound from '../../paginas/NotFound.js';

function App() {

    return (
        
        <BrowserRouter>
        
            <Layout>
                <ScrollToTop/>
                <Switch>
                
                    <Route exact path="/" component={Inicio}/>
                    <Route exact path="/acerca-de" component={AcercaDe}/>
                    <Route exact path="/privacidad" component={Privacidad}/>
                    <Route exact path="/buscar" component={BuscarCuenta}/>

                    <Route exact path="/fortin-tech" component={FortinTech}/>
                    <Route exact path="/fortín-tech" component={FortinTech}/>
                    <Route exact path="/adolfo" component={Adolfo}/>
                    <Route exact path="/armando" component={Armando}/>
                    
                    <Route exact path="/crear-cuenta" component={CrearCuenta}/>
                    <Route exact path="/iniciar-sesion" component={IniciarSesion}/>
                    <Route exact path="/recuperar-cuenta" component={RecuperarCuenta}/>

                    <Route exact path="/perfil" component={IniciarSesion}/>
                    <Route exact path="/editar-perfil" component={Inicio}/>
                    <Route exact path="/contactos" component={IniciarSesion}/>

                    <Route path={"/:nombreUsuario"} render={ props => <PerfilExterno {...props} /> } />
                    <Route component={NotFound}/>

                </Switch>

            </Layout>
        
        </BrowserRouter>
        
    );

    
}


export default App;