import React from 'react'
import ReactDOM from 'react-dom';

import '../global.css';
import TarjetaPerfil from '../componentes/TarjetaPerfil.js';

// Objetos personales
import Usuario from '../componentes/SesionIniciada/ObjetoUsuario.js';
import Tarea from '../componentes/SesionIniciada/ObjetoTarea.js';
import Siguiendo from '../componentes/SesionIniciada/ObjetoSiguiendo.js';
import BadgeTareas from '../componentes/SesionIniciada/BadgeComentarios.js';

// Importamos los componentes de Firebase
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getFirestore, collection, getDocs, query, /* where, */ orderBy } from "firebase/firestore";
import { setDoc, getDoc, doc } from "firebase/firestore";

// Importamos los componentes de React-pdf
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
// import ReactPDF from '@react-pdf/renderer';
import { PDFViewer } from '@react-pdf/renderer';
import { PDFDownloadLink } from '@react-pdf/renderer';

// ------------------------

const auth = getAuth();
const db = getFirestore();

var mUid;

var numeroFans = 0;
var mensajeFans = "";

// Sobre las tareas programadas
var numeroTareas = 0;
var mensajeNumeroTareas = "";

// Formato "día, fecha de mes de año"
const formatoFecha = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
const formatoFechaCorta = { month: 'long', day: 'numeric' };
const formatoDiaSemana = { weekday: 'long' };

const formatoHora = { hour: 'numeric', minute: 'numeric', hour12: true };

var fechaString = "";
// var horaString = ""; // Se maneja dentro de la lista
var nombrePublico = "";
var nombreUsuario = "";

// Sobre el filtro de días
var date = new Date();

var diaMenos2 = new Date();
var diaMenos1 = new Date();
var diaActual = new Date();
var diaMas1 = new Date();
var diaMas2 = new Date();

// Variable de tipo Usuario.class
var mUsuario = new Usuario('','','','','','','','');
// var objTarea = new Tarea('','','','');

// ------------------------

class Inicio extends React.Component{


    /* Datos iniciales */
    constructor(props) {
        super(props);

        const user = auth.currentUser;
        mUid = auth.currentUser.uid;
        // console.log("UID: "+mUid);

        // Días del filtro
        diaMenos2.setDate(date.getDate()-2);
        diaMenos1.setDate(date.getDate()-1);
        diaMas1.setDate(date.getDate()+1);
        diaMas2.setDate(date.getDate()+2);

        // Se convierte en texto
        const diaMenos2String = diaMenos2.toLocaleDateString("es-MX", formatoFechaCorta);
        const diaMenos1String = diaMenos1.toLocaleDateString("es-MX", formatoFechaCorta);
        const diaMas1String = diaMas1.toLocaleDateString("es-MX", formatoFechaCorta);
        const diaMas2String = diaMas2.toLocaleDateString("es-MX", formatoFechaCorta);

        // const diaSemanaMenos2String = diaMenos2.toLocaleDateString("es-MX", formatoDiaSemana);
        const diaSemanaMenos2String = diaMenos2.toLocaleDateString("es-MX", formatoFecha);
        const diaSemanaMenos1String = diaMenos1.toLocaleDateString("es-MX", formatoFecha);
        const diaSemanaActualString = diaActual.toLocaleDateString("es-MX", formatoFecha);
        const diaSemanaMas1String = diaMas1.toLocaleDateString("es-MX", formatoFecha);
        const diaSemanaMas2String = diaMas2.toLocaleDateString("es-MX", formatoFecha);

        this.state = {
            correo: user.email,

            mensajeTareas: "",
            botonGenerarPDF: "",
            idReferencia: "",
            ListaTareas:[],

            
            diaMenos2: diaMenos2String,
            diaMenos1: diaMenos1String,
            diaActual: "Hoy",
            diaMas1: diaMas1String,
            diaMas2: diaMas2String,

            titleDiaMenos2: diaSemanaMenos2String,
            titleDiaMenos1: diaSemanaMenos1String,
            titleDiaActual: diaSemanaActualString,
            titleDiaMas1: diaSemanaMas1String,
            titleDiaMas2: diaSemanaMas2String
        };

        // Si mUsuario NO TIENE datos (se solicitan a la BD)
        if (mUsuario.nombrePublico === ""){

            mUid = auth.currentUser.uid;

            // mUsuario = new Funciones().getUsuario(mUid);
            // console.log("Perfil: \n\n" + mUsuario.rutaFotoPerfil);
            const ref = doc(db, "Usuarios", mUid).withConverter(Usuario.usuarioConverter);
            const docSnap =  getDoc(ref);

            // Se piden los datos
            docSnap.then((snapshot) => {
                
                // Se rellenan los datos
                mUsuario = snapshot.data();
                    
                return Promise.resolve(docSnap);
            });

        }

        this.getListaFans();
        this.getListaTareas(date);

        // Handles's
        this.handleMensajeSobreFans = this.handleMensajeSobreFans.bind(this);
        
        this.handleCopiarEnlacePerfil = this.handleCopiarEnlacePerfil.bind(this);
        this.handleCrearPDF = this.handleCrearPDF.bind(this);

        this.handleAgregarTarea = this.handleAgregarTarea.bind(this);
        this.handleMensajeSobreTareas = this.handleMensajeSobreTareas.bind(this);

        this.handleDiaMenos2 = this.handleDiaMenos2.bind(this);
        this.handleDiaMenos1 = this.handleDiaMenos1.bind(this);
        this.handleDiaMas1 = this.handleDiaMas1.bind(this);
        this.handleDiaMas2 = this.handleDiaMas2.bind(this);
    }

    /* Evento de input */
    eventInput() {

        var tareaValue = document.getElementById("tarea");

        // Listener de input ----------
        document.addEventListener('input', () => {

            tareaValue.style.height = 0;
            tareaValue.style.height = (tareaValue.scrollHeight) + "px";

        }, false);
        // document.removeEventListener('input', , false);    
    }
    

    // FANS ------------------------
    async getListaFans(){

        const referenciaFans = collection(db, `Fans/${mUid}/Personas`);

        // Create a query against the collection.
        const q = query(referenciaFans).withConverter(Siguiendo.siguiendoConverter);
        const querySnapshot = getDocs(q);

        // Obtenemos cuántas personas siguen esta cuenta
        numeroFans = (await querySnapshot).size;

        // Dependiendo del número es lo que se muestra
        if(numeroFans === 0){

            mensajeFans = `Aún no hay personas que te agreguen a su lista de contactos`;
        } else if (numeroFans === 1){

            mensajeFans = `1 persona te agregó a su lista de contactos`;
        } else {

            mensajeFans = `${numeroFans} personas te han agregado a su lista de contactos`;
        }

    }

    // FILTRO ----------------------
    async ordenarFiltroFechas(diasDiferencia){ // Días de diferencia respecto a la view

        // Se inician las variables de texto
        var diaMenos2String = "";
        var diaMenos1String = "";
        var diaActualString = ""; // --
        var diaMas1String = "";
        var diaMas2String = "";

        var diaSemanaMenos2String = "";
        var diaSemanaMenos1String = "";
        // var diaSemanaActualString = "";
        var diaSemanaMas1String = "";
        var diaSemanaMas2String = "";

        // Se suman n días
        diaMenos2.setDate(diaMenos2.getDate() + diasDiferencia);
        diaMenos1.setDate(diaMenos1.getDate() + diasDiferencia);
        diaActual.setDate(diaActual.getDate() + diasDiferencia); // --
        diaMas1.setDate(diaMas1.getDate() + diasDiferencia);
        diaMas2.setDate(diaMas2.getDate() + diasDiferencia);

        // Se convierte en texto
        diaMenos2String = diaMenos2.toLocaleDateString("es-MX", formatoFechaCorta);
        diaMenos1String = diaMenos1.toLocaleDateString("es-MX", formatoFechaCorta);
        diaActualString = diaActual.toLocaleDateString("es-MX", formatoDiaSemana) +", "+ diaActual.toLocaleDateString("es-MX", formatoFechaCorta); // --
        diaMas1String = diaMas1.toLocaleDateString("es-MX", formatoFechaCorta);
        diaMas2String = diaMas2.toLocaleDateString("es-MX", formatoFechaCorta);

        diaSemanaMenos2String = diaMenos2.toLocaleDateString("es-MX", formatoFecha);
        diaSemanaMenos1String = diaMenos1.toLocaleDateString("es-MX", formatoFecha);
        // diaSemanaActualString = diaActual.toLocaleDateString("es-MX", formatoFecha);
        diaSemanaMas1String = diaMas1.toLocaleDateString("es-MX", formatoFecha);
        diaSemanaMas2String = diaMas2.toLocaleDateString("es-MX", formatoFecha);

        // Cambia el title del botón
        this.setState(() => {
            return {
                titleDiaMenos2: diaSemanaMenos2String,
                titleDiaMenos1: diaSemanaMenos1String,
                titleDiaActual: "Vista actual",
                titleDiaMas1: diaSemanaMas1String,
                titleDiaMas2: diaSemanaMas2String
            };
        });


        // Lógica de Textos en los botones
        if ( diaActual.getDate() === date.getDate() && diaActual.getMonth() === date.getMonth() && diaActual.getFullYear() === date.getFullYear() ){

            // Cambia el texto del botón
            this.setState(() => {
                return {
                    diaMenos2: diaMenos2String,
                    diaMenos1: diaMenos1String,
                    diaActual: "Hoy",
                    diaMas1: diaMas1String,
                    diaMas2: diaMas2String
                };
            });

        } else if ( (diaActual.getDate()-2) === date.getDate() && diaActual.getMonth() === date.getMonth() && diaActual.getFullYear() === date.getFullYear() ){

            // Cambia el texto del botón
            this.setState(() => {
                return {
                    diaMenos2: "Hoy",
                    diaMenos1: diaMenos1String,
                    diaActual: diaActualString,
                    diaMas1: diaMas1String,
                    diaMas2: diaMas2String
                };
            });
            
        } else if ( (diaActual.getDate()-1) === date.getDate() && diaActual.getMonth() === date.getMonth() && diaActual.getFullYear() === date.getFullYear() ){

            // Cambia el texto del botón
            this.setState(() => {
                return {
                    diaMenos2: diaMenos2String,
                    diaMenos1: "Hoy",
                    diaActual: diaActualString,
                    diaMas1: diaMas1String,
                    diaMas2: diaMas2String
                };
            });
            
        } else if ( (diaActual.getDate()+1) === date.getDate() && diaActual.getMonth() === date.getMonth() && diaActual.getFullYear() === date.getFullYear() ){

            // Cambia el texto del botón
            this.setState(() => {
                return {
                    diaMenos2: diaMenos2String,
                    diaMenos1: diaMenos1String,
                    diaActual: diaActualString,
                    diaMas1: "Hoy",
                    diaMas2: diaMas2String
                };
            });
            
        } else if ( (diaActual.getDate()+2) === date.getDate() && diaActual.getMonth() === date.getMonth() && diaActual.getFullYear() === date.getFullYear() ){

            // Cambia el texto del botón
            this.setState(() => {
                return {
                    diaMenos2: diaMenos2String,
                    diaMenos1: diaMenos1String,
                    diaActual: diaActualString,
                    diaMas1: diaMas1String,
                    diaMas2: "Hoy",
                };
            });
            
        } else {

            // Cambia el texto del botón
            this.setState(() => {
                return {
                    diaMenos2: diaMenos2String,
                    diaMenos1: diaMenos1String,
                    diaActual: diaActualString,
                    diaMas1: diaMas1String,
                    diaMas2: diaMas2String
                };
            });
            
        }

        // Obtenemos las tareas de la fecha de interés
        this.getListaTareas(diaActual);

        // Reseteamos la vista del PDF
        const App = () => (<div />);
        ReactDOM.render(<App />, document.getElementById('visor'));

    }

    // TAREAS ----------------------
    async getListaTareas(dateInteres){
        
        // Creamos una lista local
        let lista = [];
        let listaCorta = [];

        const referenciaComentarios = collection(db, `Tareas/${mUid}/Mensaje`);

        // Create a query against the collection.
        const q = query(referenciaComentarios, orderBy("date", "asc")).withConverter(Tarea.tareaConverter);
        const querySnapshot = getDocs(q);

        // ---------------------

        querySnapshot
        .then((snapshot) => {

                snapshot.forEach((documento) => {

                    // doc.data() is never undefined for query doc snapshots
                    
                    // console.log(doc.id, " => ", doc.data());

                    let idReferencia = documento.id;
                    // var objTarea = doc.data();

                    let uid = documento.data().uid;
                    let date = documento.data().date;
                    let texto = documento.data().texto;
                    let leido = documento.data().leido;

                    // Convertimos Timestamp a Date
                    const dateTarea = date.toDate();

                    // ------------
                    // https://stackoverflow.com/questions/52247445/how-do-i-convert-a-firestore-date-timestamp-to-a-js-date

                    // Example: Friday Nov 27 2017
                    // const date = dateTarea.toDateString();

                    // Example: 01:10:18 AM, the locale part 'en-US' is optional
                    // const time = dateTarea.toLocaleTimeString('en-US');
                    // ------------                   


                    // Si coincide la Fecha de la Tarea con la Fecha de Interés
                    if ( dateTarea.getDate() === dateInteres.getDate() && dateTarea.getMonth() === dateInteres.getMonth() && dateTarea.getFullYear() === dateInteres.getFullYear() ){

                        /* NECESARIO para el PDF */

                        // "día, fecha de mes de año"
                        fechaString = dateTarea.toLocaleDateString("es-MX", formatoFecha);
                        // "hora:minuto am/pm"
                        var horaString = dateTarea.toLocaleTimeString("es-MX", formatoHora);

                        // Creamos un objeto local
                        let objCorto = { idReferencia };

                        // Agregamos el objeto local a la lista local
                        listaCorta.push(objCorto);
                    

                        var objetoUsuario = new Usuario('','','','','','','','');
                        const ref = doc(db, "Usuarios", uid).withConverter(objetoUsuario.usuarioConverter);
                        const docSnap =  getDoc(ref);

                            // Se piden los datos
                            docSnap.then((snapshot) => {
                                                        
                                objetoUsuario = snapshot.data();
                                nombrePublico = objetoUsuario.nombrePublico;
                                nombreUsuario = objetoUsuario.nombreUsuario;

                                // Creamos un objeto local
                                let objeto = {idReferencia, uid, date, texto, leido, horaString, nombrePublico, nombreUsuario };

                                // Agregamos el objeto local a la lista local
                                lista.push(objeto);

                                // Se actualiza el estado
                                this.setState(() => {
                                    return {
                                        idReferencia: idReferencia,
                                        ListaTareas: lista
                                    }
                                });
                        });
                        

                    } else { // Si no coincide, se limpian la vista

                        //No se devuelve un Objeto Tarea
                        idReferencia = "";

                        this.setState(() => {
                                    
                            return {
                                idReferencia: "",
                                ListaTareas: lista
                            }
                        });
                    }

                    // Obtenemos el número de tareas para la fecha de interés
                    numeroTareas = listaCorta.length;

                    // Dependiendo del número es lo que se muestra
                    if(numeroTareas === 0){

                        mensajeNumeroTareas = `No tienes tareas programadas para este día`;
                        this.setState(() => {                    
                            return {
                                mensajeTareas: mensajeNumeroTareas,
                                botonGenerarPDF: ""
                            }
                        });

                    } else if (numeroTareas === 1){

                        mensajeNumeroTareas = `Tienes 1 tarea programada`;
                        this.setState(() => {                    
                            return {
                                mensajeTareas: mensajeNumeroTareas,
                                botonGenerarPDF: "Generar PDF"
                            }
                        });

                    } else {

                        mensajeNumeroTareas = `${numeroTareas} tareas programadas`;
                        this.setState(() => {                    
                            return {
                                mensajeTareas: mensajeNumeroTareas,
                                botonGenerarPDF: "Generar PDF"
                            }
                        });
                    }
    
                });

                if(snapshot.empty){

                    //No se devuelve un Objeto Tarea
                    this.setState(() => {
                                
                        return {
                            idReferencia: "",
                            ListaTareas: lista
                        }
                    });

                }

        })
        .catch((error) => {
                // An error happened.
                const errorCode = error.code;
                const errorMessage = error.message;

                console.log("No se pudo realizar la búsqueda: " + errorCode + "\n\n" + errorMessage);
        });
    }

    /* Eventos */
    handleMensajeSobreFans = (e) => {

        e.preventDefault();

        const mensaje = `Personas que te han agregado a su lista de contactos`;
        alert(mensaje);
    }
    
    handleCopiarEnlacePerfil = (e) => {
        e.preventDefault();

        /* const datos = {
          title: 'AgendaPDF',
          text: ` | Agrega mi perfil de trabajo en agendapdf.com/${mUsuario.nombreUsuario}`,
        }

        // Si el navegador lo soporta y el dispositivo lo soporta
        if (navigator.canShare && navigator.canShare(datos)) {

          try {

            navigator.share(datos);

          } catch (err) {

            if (err.name !== 'AbortError') {
              console.error(err.name, err.message)
            }

          }

        } else { // El navegador no lo soporta o el dispositivo lo no soporta
          console.warn('El navegador no lo soporta o el dispositivo lo no soporta', datos);
        } */


        const urlPerfil = `agendapdf.com/${mUsuario.nombreUsuario}`;

        navigator.clipboard.writeText(urlPerfil)
        .then(() => {
            
            // console.log("Text copied to clipboard...");
            alert("¡Enlace copiado al portapapeles!");
        }).catch(err => {
            // console.log('Something went wrong', err);
            console.warn('El navegador no lo soporta o el dispositivo lo no soporta', err);
        });
    }

    handleCrearPDF = (e) => {

        e.preventDefault();

        // Si la lista de tareas está vacía
        if(numeroTareas === 0){

            const mensaje = `No se puede crear un PDF porque no tienes tareas programadas para esta fecha.`;
            alert(mensaje);

        } else { // Si no

            /* Estilos de fuentes */
            const styles = StyleSheet.create({
                page: { flexDirection: 'row', backgroundColor: '#fff' },
                section: { margin: 10, padding: 10, flexGrow: 1 },

                titulo: { marginTop: 10, paddingTop: 20, color: '#0071e3', backgroundColor: '#e7e7e750', textAlign: 'center', fontSize: '23'},
                subtitulo: { marginBottom: 10, paddingBottom: 20, backgroundColor: '#e7e7e750', textAlign: 'center', fontSize: '13'  },
                metadatos: { marginTop: 20, marginBottom: 10, fontSize: '18'  },
                tarea: { marginVertical: 5, fontSize: '14' },
                autor: { marginVertical: 5, fontSize: '10', color: '#004F9E' },

                responsable: { textAlign: 'right', marginTop: 20, marginBottom: 10, color: '#0071e3', fontSize: '11' },

                pie: { marginHorizontal: 0, marginBottom: 10, padding: 10, color: '#0071e3', backgroundColor: '#e7e7e750', fontSize: '14' },
                empresa: { textAlign: 'left', marginBottom: -8 },
                firma: { textAlign: 'right', marginTop: -8 }
            });


            /* Creamos la vista del PDF */
            const MyDocument = () => (
                <Document id="visor">
                    <Page size="A4" style={styles.page}>
                        <View style={styles.section}>
                            
                            <Text style={styles.titulo}>Programa de actividades</Text>
                            <Text style={styles.subtitulo}>{"\n" + fechaString}</Text>
                            <Text style={styles.metadatos}>{"Tareas pendientes:\n\n"}</Text>
                            
                            {this.state.ListaTareas.map((tarea) => (

                                <div>
                                    <Text style={styles.tarea}>
                                    (  )  {tarea.horaString} • {tarea.texto}
                                    </Text>
                                    <Text style={styles.autor}>
                                    {"Programado por @" + tarea.nombreUsuario + "\n\n\n"}
                                    </Text>
                                </div>

                            ))}

                            <Text style={styles.responsable}>Agenda de {mUsuario.nombrePublico}</Text>
                            <div style={styles.pie}>
                                <Text style={styles.empresa}>AgendaPDF.com</Text>
                                <Text style={styles.firma}>Desarrollado por Fortín Tech</Text>
                            </div>
                            
                        </View>
                    </Page>
                </Document>
            );

            /* Reemplazamos el div "visor" */
            const App = () => (
                <PDFViewer className="visor-pdf-pantalla-completa">
                    <MyDocument />
                </PDFViewer>
            );
            ReactDOM.render(<App />, document.getElementById('visor'));
            

            // Cargamos una URL de descarga
            const Descargar = () => (
                <div>
                  <PDFDownloadLink document={<MyDocument />} fileName={"Tareas del " + fechaString + ".pdf"}>
                    {({ blob, url, loading, error }) =>
                      loading ? 'Procesando...' : 'Descargar'
                    }
                  </PDFDownloadLink>
                </div>
            );
            ReactDOM.render(<Descargar />, document.getElementById('descargar'));

        }

    }

    handleAgregarTarea = (e) => {
        e.preventDefault();

        const tareaNueva = e.target.elements.tarea.value;
        const fechaNueva = e.target.elements.fecha.value;
        const horaNueva = e.target.elements.hora.value;

        const uidDeUsuario = mUid;


        if (uidDeUsuario !== "") {
            // Si obtengo una uid existente

            onAuthStateChanged(auth, (user) => {
                if (user) {
                    mUid = auth.currentUser.uid;

                    // Aquí agregamos la estructura Siguiendo > mUid > objetoUsuarioSimple { uid, date }
                    // console.log("Siguiendo a: " + uidDeUsuario);


                    if (tareaNueva === null || tareaNueva === "") {
                        const mensaje = `Escribe una TAREA para programarla`;
                        alert(mensaje);

                    } else if (fechaNueva === null || fechaNueva === "") {
                        const mensaje = `Escoge una FECHA para programarla`;
                        alert(mensaje);

                    } else if (horaNueva === null || horaNueva === "") {
                        const mensaje = `Escoge una HORA para programarla`;
                        alert(mensaje);

                    } else {

                        // const date = new Date();
                        const fecha = new Date();
                        
                        // Separamos los datos de la fecha
                        const [anio, mes, dia] = fechaNueva.split("-");
                        const value1 = Number(anio);
                        const value2 = Number(mes) - 1; // Enero inicia en 0
                        const value3 = Number(dia);
                        fecha.setFullYear(value1);
                        fecha.setMonth(value2);
                        fecha.setDate(value3);
                        
                        // Separamos los datos de la hora
                        const [hora, minuto] = horaNueva.split(":");
                        const val1 = Number(hora);
                        const val2 = Number(minuto);
                        fecha.setHours(val1);
                        fecha.setMinutes(val2);

                        const mensajeFecha =
                        "_fecha_" +
                        fecha.getFullYear() +
                        "_" +
                        (fecha.getMonth()) +
                        "_" +
                        (fecha.getDate()) +
                        "_" +
                        fecha.getHours() +
                        "_" +
                        fecha.getMinutes() +
                        "_" +
                        fecha.getSeconds();

                        const idReferenciaString = "autor_" + mUid + mensajeFecha;

                        // const mensaje = `Tiempo del servidor: ` + serverTimestamp();
                        console.log(fecha);
                        
                        const objTarea = new Tarea(mUid, fecha, tareaNueva, false);

                        const ref = doc( db, `Tareas/${uidDeUsuario}/Mensaje`, idReferenciaString).withConverter(objTarea.tareaConverter);
                        setDoc(ref, objTarea);

                        alert("✅ ¡Tarea agregada!");

                        // Reiniciamos los datos del formulario
                        var tareaValue = document.getElementById("tarea");
                        if (tareaValue.value !== "") {
                            tareaValue.value = "";
                        }

                        // La fecha no se reinicia para agilizar la creación de múltiples tareas

                        // Reiniciamos los datos de la hora
                        var horaValue = document.getElementById("hora");
                        if (horaValue.value !== "") {
                            horaValue.value = "";
                            this.getListaTareas(date);
                        }

                        const docSnap = getDoc(ref);
                        return Promise.resolve(docSnap);
                    }





                } else {
                    const mensaje = `Para programes una tarea es necesario que inicies sesión`;
                    // alert(mensaje);
                    console.log(mensaje);

                    // Cambia el texto del botón
                    this.setState(() => {
                        return {
                            mensajeBotonAgregarTarea: "Antes inicia sesión",
                        };
                    });
                }
            });

        } else {
            // Si no tengo una uid existente
            mUid = auth.currentUser.uid;
        }
    };

    handleMensajeSobreTareas = (e) => {

        e.preventDefault();

        const mensaje = `Si compartes tu perfil podrán agregarte tareas`;
        alert(mensaje);        
    }

    // FILTRO de días

    handleDiaMenos2 = (e) => {
        e.preventDefault();

        // Se restan 2 días
        this.ordenarFiltroFechas(-2);
    }
    handleDiaMenos1 = (e) => {
        e.preventDefault();

        // Se resta 1 día
        this.ordenarFiltroFechas(-1);
    }

    handleDiaMas1 = (e) => {
        e.preventDefault();

        // Se suma 1 día
        this.ordenarFiltroFechas(+1);
    }
    handleDiaMas2 = (e) => {
        e.preventDefault();

        // Se suman 2 días
        this.ordenarFiltroFechas(+2);
    }

    // -----------------------------

    /* Lo que mostramos en pantalla */
    render() {
        return (


            <React.Fragment>

                <div className="header-pantalla-completa">
                    
                    {/* Vista de perfil */}
                    <div className="header-un-tercio-pantalla">

                        <div id="perfilLocal">
                        <TarjetaPerfil
                            suscrito={mUsuario.suscrito}
                            verificado={mUsuario.verificado}

                            nombrePublico={mUsuario.nombrePublico}
                            nombreUsuario={mUsuario.nombreUsuario}
                            rutaFotoPerfil={mUsuario.rutaFotoPerfil}
                            biografia={mUsuario.biografia}

                            permisosDeContacto = {true}
                            clavePais={mUsuario.clavePais}
                            numeroTelefono={mUsuario.numeroTelefono}
                            correo={this.state.correo}
                            />
                        </div>
                        

                        <span className="link-fans" onClick={this.handleMensajeSobreFans}>{mensajeFans}</span>

                        <button className="btn btn-seguir-usuario" onClick={this.handleCopiarEnlacePerfil}>Copiar enlace de perfil</button>
                        
                    </div>

                    {/* Formulario de Tarea Nueva */}
                    <div className="header-dos-tercios-pantalla">

                        {/* Intrucciones */}
                        <br />
                        <br />
                        <span className="span-centrado-light">Intrucciones</span>
                        <span>• Agrega todos las tareas que quieras (con fecha y hora).</span><br/>
                        <span>• Muévete por el filtro de fechas.</span><br/>
                        <span>• Genera un PDF con todos tus pendientes.</span><br/>
                        <span>• Busca personas o comparte tu enlace para trabajar en equipos.</span><br/>

                        {/* Formulario */}
                        <form onSubmit={this.handleAgregarTarea} className="formulario">

                            <div className="formulario">
                                <label></label>
                                {/* Elemento textarea autoFocus  */}
                                <textarea id="tarea" className="input-tarea" type="text" maxLength={2000} onInput={this.eventInput()}
                                name="tarea" placeholder="Escribe una tarea o pendiente"/>                                
                            </div>

                            <div className="formulario-tarea">
                                <label className="label-tarea">Fecha: </label>
                                <input type="date" name="fecha" id="fecha"/>
                            </div>
                            <div className="formulario-tarea">
                                <label className="label-tarea">Hora: </label>
                                <input type="time" name="hora" id="hora"/>
                            </div>

                            <button className="btn btn-login">Agregar tarea</button>
                            <br />
                        
                        </form>

                        
                    </div>


                    {/* Filtro de días */}
                    <div className="filtro-pantalla-completa" >
                        <button className="btn btn-filtro-dia" onClick={this.handleDiaMenos2} title={this.state.titleDiaMenos2}>{this.state.diaMenos2}</button>
                        <button className="btn btn-filtro-dia" onClick={this.handleDiaMenos1} title={this.state.titleDiaMenos1}>{this.state.diaMenos1}</button>
                        <span className="span-filtro-dia" title={this.state.titleDiaActual}>{this.state.diaActual}</span>
                        <button className="btn btn-filtro-dia" onClick={this.handleDiaMas1} title={this.state.titleDiaMas1}>{this.state.diaMas1}</button>
                        <button className="btn btn-filtro-dia" onClick={this.handleDiaMas2} title={this.state.titleDiaMas2}>{this.state.diaMas2}</button>
                    </div>
                    
                    {/* Tareas programadas */}
                    <div className="header-pantalla-completa">

                        {this.state.ListaTareas.map((tarea) => (

                            <BadgeTareas
                            key={tarea.id}

                            idItem={`ID${tarea.idReferencia}`}

                            // idCreador={mUid}
                            uidPersona={mUid}

                            idReferencia={tarea.idReferencia}

                            uid={tarea.uid}
                            date={tarea.date}
                            texto={tarea.texto}
                            leido={tarea.leido}
                            
                            horaString={tarea.horaString}
                            nombrePublico={tarea.nombrePublico}
                            nombreUsuario={tarea.nombreUsuario} />

                        ))}

                    </div>

                    {/* Botón para generar PDF */}
                    <div style={{ display: 'flex', width: '100%' }}>
                        <span className='span-normal'>{this.state.mensajeTareas}</span> <button hidden={!this.state.botonGenerarPDF} className="btn btn-generar-pdf" onClick={this.handleCrearPDF}>{this.state.botonGenerarPDF}</button><span id="descargar" hidden={!this.state.botonGenerarPDF}/>
                    </div>
                    <br/><br/>

                    {/* Visor PDF */}
                    <div className="visor-pdf-pantalla-completa" id="visor"/>


                </div>


            </React.Fragment>


        );
    }
}


export default Inicio;