import React from 'react'

import '../global.css';

import BadgeSiguiendo from '../componentes/SesionIniciada/BadgeSiguiendo.js';

import { getAuth } from "firebase/auth";

// Importamos los componentes a Firebase
import { getFirestore, collection, getDocs, query, orderBy } from "firebase/firestore";


const auth = getAuth();
const db = getFirestore();

var idSiguiendo = "";
var mUid;

var numeroContactos = 0;
var mensajeContactos = "";

// ------------------------
class Siguiendo {
    constructor (uid, date) {

        this.uid = uid;
        this.date = date;
    }
    toString() {
        return this.uid + ', ' + this.date;
    }
}

// Firestore data converter
const siguiendoConverter = {
    toFirestore: (siguiendo) => {
        return {
            uid: siguiendo.uid,
            date: siguiendo.date
        };
    },
    fromFirestore: (snapshot, options) => {
        const data = snapshot.data(options);
        return new Siguiendo(data.uid, data.date);
    }
};
// ------------------------


class Contactos extends React.Component{

    constructor(props) {
        super(props);

        // Se muestran datos provisionales
        this.state = {
            ListaSiguiendo:[],
        };

        mUid = auth.currentUser.uid;

        this.getListaContactos();

        // Handles's
        this.handleMensajeSobreComentarios = this.handleMensajeSobreContactos.bind(this);
    }


    async getListaContactos(){

        let lista = [];

        const referenciaSiguiendo = collection(db, `Siguiendo/${mUid}/Personas`);

            // Create a query against the collection.
            const q = query(referenciaSiguiendo, orderBy("date", "desc")).withConverter(siguiendoConverter);
            const querySnapshot = getDocs(q);

            // Obtenemos cuántas personas siguen esta cuenta
            numeroContactos = (await querySnapshot).size;

            // Dependiendo del número es lo que se muestra
            if(numeroContactos === 0){

                mensajeContactos = `Aún no agrega personas a su lista de contactos`;
            } else if (numeroContactos === 1){

                mensajeContactos = `1 persona agregada a su lista de contactos`;
            } else {

                mensajeContactos = `${numeroContactos} personas agregadas a su lista de contactos`;
            }

            // Obtenemos los datos de la base de datos
            querySnapshot
            .then((snapshot) => {

                snapshot.forEach((doc) => {

                    // doc.data() is never undefined for query doc snapshots
                    
                    // console.log(doc.id, " => ", doc.data());

                    idSiguiendo = doc.id;
                    // mSiguiendo = doc.data();

                    let uid = doc.data().uid;
                    let date = doc.data().date;

                    let objeto = {idSiguiendo, uid, date};

                    lista.push(objeto);

                    this.setState(() => {
                                
                        return {
                            
                            ListaSiguiendo: lista
                        }
                    });
    
                });

                if(snapshot.empty){

                    //No se devuelve un Objeto Siguiendo
                    idSiguiendo = "";

                    this.setState(() => {
                                
                        return {
                            
                            ListaSiguiendo: lista
                        }
                    });

                }

            })
            .catch((error) => {
                // An error happened.
                const errorCode = error.code;
                const errorMessage = error.message;

                console.log("No se pudo realizar la búsqueda: " + errorCode + "\n\n" + errorMessage);
            });
    }

    handleMensajeSobreContactos = (e) => {

        e.preventDefault();

        const mensaje = `AgendaPDF es ideal para trabajar en equipos grandes y pequeños`;
        alert(mensaje);        
    }


    render() {
        return (


            <React.Fragment>

                <div className="header-pantalla-completa">

                    <div >

                        <br/>
                        <span className="link-fans" onClick={this.handleMensajeSobreContactos}><b>{this.state.mensajeContactos}</b></span>
                        <span className="link-fans" onClick={this.handleMensajeSobreContactos}><b>{mensajeContactos}</b></span>
                        <br/>

                    </div>



                    <div className="header-pantalla-completa">

                        {this.state.ListaSiguiendo.map(siguiendo => (
                            
                            <BadgeSiguiendo
                                idCreador={mUid}
                                uid={siguiendo.uid}
                                date={siguiendo.date} />

                        ))}

                    </div>


                </div>


            </React.Fragment>


        );
    }
}


export default Contactos;