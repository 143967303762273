import React from 'react'

import '../global.css';

import BadgeTareas from '../componentes/SesionIniciada/BadgeComentarios.js';

import { getAuth } from "firebase/auth";

// Importamos los componentes a Firebase
import { getFirestore, collection, getDocs, query, orderBy } from "firebase/firestore";


const auth = getAuth();
const db = getFirestore();

var idReferencia = "";
var mUid;

var numeroTareas = 0;
var mensajeNumeroTareas = "";



// ------------------------
class Tarea {
    constructor (uid, date, texto, leido) {

        this.uid = uid;
        this.date = date;
        this.texto = texto;
        this.leido = leido;
    }
    toString() {
        return this.uid + ', ' + this.date + ', ' + this.texto + ', ' + this.leido;
    }
}

// Firestore data converter
const tareaConverter = {
    toFirestore: (tarea) => {
        return {
            uid: tarea.uid,
            date: tarea.date,
            texto: tarea.texto,
            leido: tarea.leido
        };
    },
    fromFirestore: (snapshot, options) => {
        const data = snapshot.data(options);
        return new Tarea(data.uid, data.date, data.texto, data.leido);
    }
};



class Inicio extends React.Component{

    

    constructor(props) {
        super(props);

        // Se muestran datos provisionales
        this.state = {

            mensajeTareas: "",
            idReferencia: "",
            ListaTareas:[],
        };


        mUid = auth.currentUser.uid;
        // console.log("UID: "+mUid);

        this.getListaTareas();

        // Handles's
        this.handleMensajeSobreTareas = this.handleMensajeSobreTareas.bind(this);
    }

  
    async getListaTareas(){
        let lista = [];

        const referenciaComentarios = collection(db, `Tareas/${mUid}/Mensaje`);

            // Create a query against the collection.
            const q = query(referenciaComentarios, orderBy("date", "asc")).withConverter(tareaConverter);
            const querySnapshot = getDocs(q);

            // Obtenemos cuántas personas siguen esta cuenta
            numeroTareas = (await querySnapshot).size;

            // Dependiendo del número es lo que se muestra
            if(numeroTareas === 0){

                mensajeNumeroTareas = `Aún no tienes tareas programadas`;
                this.setState(() => {                    
                    return {
                        mensajeTareas: mensajeNumeroTareas,
                    }
                });

            } else if (numeroTareas === 1){

                mensajeNumeroTareas = `Tienes 1 tarea programada`;
                this.setState(() => {                    
                    return {
                        mensajeTareas: mensajeNumeroTareas,
                    }
                });

            } else {

                mensajeNumeroTareas = `${numeroTareas} tareas programadas`;
                this.setState(() => {                    
                    return {
                        mensajeTareas: mensajeNumeroTareas,
                    }
                });
            }

            // ---------------------
            querySnapshot
            .then((snapshot) => {

                snapshot.forEach((doc) => {

                    // doc.data() is never undefined for query doc snapshots
                    
                    // console.log(doc.id, " => ", doc.data());

                    idReferencia = doc.id;
                    // mSiguiendo = doc.data();

                    let uid = doc.data().uid;
                    let date = doc.data().date;
                    let texto = doc.data().texto;
                    let leido = doc.data().leido;

                    let objeto = {idReferencia, uid, date, texto, leido};

                    lista.push(objeto);

                    this.setState(() => { 
                        return {

                            idReferencia: idReferencia,
                            ListaTareas: lista
                        }
                    });
    
                });

                if(snapshot.empty){

                    //No se devuelve un Objeto Comentario
                    idReferencia = "";

                    this.setState(() => {
                                
                        return {
                            idReferencia: "",
                            ListaTareas: lista
                        }
                    });

                }

            })
            .catch((error) => {
                // An error happened.
                const errorCode = error.code;
                const errorMessage = error.message;

                console.log("No se pudo realizar la búsqueda: " + errorCode + "\n\n" + errorMessage);
            });
    }

    /* onClick */
    handleMensajeSobreTareas = (e) => {

        e.preventDefault();

        const mensaje = `Si compartes tu perfil podrán agregarte tareas`;
        alert(mensaje);        
    }

    /* Lo que mostramos en pantalla */
    render() {
        return (


            <React.Fragment>

                <div className="header-pantalla-completa">

                    {/* Introducción de las Tareas */}
                    <div className="header-media-pantalla">

                        <span className="span-centrado-light">Tareas</span>
                        <span className="link-fans" onClick={this.handleMensajeSobreTareas}><b>{this.state.mensajeTareas}</b></span>
                        <br/>

                    </div>


                    {/* Tareas programadas */}
                    <div className="header-pantalla-completa">

                        {this.state.ListaTareas.map((tarea) => (

                            <BadgeTareas
                            key={tarea.id}

                            idItem={`ID${tarea.date}`}

                            idCreador={mUid}
                            idReferencia={this.state.idReferencia}

                            uid={tarea.uid}
                            date={tarea.date}
                            texto={tarea.texto}
                            leido={tarea.leido} />

                        ))}

                    </div>


                </div>


            </React.Fragment>


        );
    }
}


export default Inicio;