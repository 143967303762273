import React from 'react';
import { Link } from 'react-router-dom';

import "../../global.css";
import "./estilos/BadgeCuentaEncontrada.css";
// import header from '../imagenes/iconos/icon_mybio_fans_512px.png';

//Imágenes
import iconCuentaVerificada from '../../imagenes/iconos/icon_usuario_verificado_128px.png'
import Textos from '../Textos.js';

// ------------------------

class BadgeCuentaEncontrada extends React.Component{

    /* Datos iniciales */
    constructor(props) {
        super(props);

        this.state = {
            botonInvitarContacto: "Invitar a AgendaPDF"
        };

        if (this.props.nombrePublico === "") {
            
            // Se actualiza el estado
            this.setState(() => {
                return {
                    botonInvitarContacto: "Invitar a AgendaPDF"
                }
            });
        }

        this.handleMensajeCuentaVerificada = this.handleMensajeCuentaVerificada.bind(this);
        this.handleInvitarContacto = this.handleInvitarContacto.bind(this);
    }

    /* Eventos */
    handleMensajeCuentaVerificada = (e) => {

        e.preventDefault();

        const mensaje = new Textos().usuarioVerificado();
        alert(mensaje);        
    }

    handleInvitarContacto = (e) => {
        e.preventDefault();

        // Cargamos los datos
        /* const datos = {
          title: 'AgendaPDF',
          text: ` | Únete a https://agendapdf.com para trabajar colaborativamente`
        }

        // Si el navegador lo soporta y el dispositivo lo soporta
        if (navigator.canShare && navigator.canShare(datos)) {

          try {

            navigator.share(datos);

          } catch (err) {

            if (err.name !== 'AbortError') {
              console.error(err.name, err.message)
            }

          }

        } else { // El navegador no lo soporta o el dispositivo lo no soporta
          console.warn('El navegador no lo soporta o el dispositivo lo no soporta', datos);
        } */

        const invitacion = `Únete a https://agendapdf.com para trabajar colaborativamente en mi red de contactos`;

        navigator.clipboard.writeText(invitacion)
        .then(() => {
            
            // console.log("Text copied to clipboard...");
            alert("¡Invitación copiada al portapapeles! Pégala en donde quieras.");
        }).catch(err => {
            // console.log('Something went wrong', err);
            console.warn('El navegador no lo soporta o el dispositivo lo no soporta', err);
        });
        
    }

    // -----------------------------

    /* Lo que mostramos en pantalla */
    render(){
        

        return(

            <div className="BadgeCuentaEncontrada">

                <div className="BadgeCuentaEncontrada__header">
                    <img className="icon-verificado" src={iconCuentaVerificada} hidden={!this.props.verificado} onClick={this.handleMensajeCuentaVerificada} alt="Estado de cuenta verificada"/>
                </div>

                <div className="BadgeCuentaEncontrada__section-name">
                    
                    <div className="imagen-avatar-mini">
                        <img src={this.props.rutaFotoPerfil} alt="Imagen de perfil"/>
                    </div>

                    <h5> {this.props.nombrePublico} </h5>
                    <h6> @{this.props.nombreUsuario} </h6>
                
            
                </div>


                <div hidden={!this.props.biografia} className="BadgeCuentaEncontrada__section-biografia">


                    <h6>{this.props.biografia}</h6>
        
                </div>

                <div className="BadgeCuentaEncontrada__icons">
                    <Link hidden={!this.props.nombrePublico} to={"/"+this.props.nombreUsuario} className="btn btn-cuenta-encontrada">Ver</Link>
                    <button hidden={this.props.nombrePublico} onClick={this.handleInvitarContacto} className="btn btn-cuenta-encontrada">{this.state.botonInvitarContacto}</button>
                </div>

            </div>
            
        );

    }

}

// Exportamos este componente
export default BadgeCuentaEncontrada;