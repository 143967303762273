export default class Usuario {

    constructor (suscrito, verificado, 
        nombrePublico, nombreUsuario, biografia, rutaFotoPerfil, 
        clavePais, numeroTelefono) {

        this.suscrito = suscrito;
        this.verificado = verificado;
        this.nombrePublico = nombrePublico;
        this.nombreUsuario = nombreUsuario;

        this.biografia = biografia;
        this.rutaFotoPerfil = rutaFotoPerfil;

        this.clavePais = clavePais;
        this.numeroTelefono = numeroTelefono;
    }
    
    toString() {
        return this.suscrito + ', ' + this.verificado + ', ' + 
        this.nombrePublico + ', ' + this.nombreUsuario + ', ' + 
        this.biografia + ', ' + this.rutaFotoPerfil + ', ' +
        this.clavePais + ', ' + this.numeroTelefono;
    }

    // Firestore data converter
    usuarioConverter = {
        toFirestore: (usuario) => {
            return {

                suscrito: usuario.suscrito,
                verificado: usuario.verificado,

                nombrePublico: usuario.nombrePublico,
                nombreUsuario: usuario.nombreUsuario,
                
                biografia: usuario.biografia,
                rutaFotoPerfil: usuario.rutaFotoPerfil,

                clavePais: usuario.clavePais,
                numeroTelefono: usuario.numeroTelefono
            };
        },
        fromFirestore: (snapshot, options) => {
            const data = snapshot.data(options);
            return new Usuario(data.suscrito, data.verificado, 
                data.nombrePublico, data.nombreUsuario,
                data.biografia, data.rutaFotoPerfil, 
                data.clavePais, data.numeroTelefono);
        }
    }

}