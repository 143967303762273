import React from 'react';
import { Link } from 'react-router-dom';

import './style.css';

// ------------------------

import iconAcercaDe from '../../imagenes/iconos/icon-info.svg';
import iconPrivacidad from '../../imagenes/iconos/icon-contactos.svg';
import iconBuscar from '../../imagenes/iconos/icon-buscar.svg';

import iconIniciarSesion from '../../imagenes/iconos/icon-iniciar-sesion.svg';

// ------------------------

class SidebarMenu extends React.Component {


    /* Lo que mostramos en pantalla */
    render(){
        return(

            <div className="Sidebar">

                <div className="Sidebar-menu" id='menu-completo'>

                    <ul className="Sidebar-list">

                        <p className="Sidebar__fuente">Menú</p>
                        <Link className="Sidebar-link" to="/acerca-de"><img className="Sidebar-icon" src={iconAcercaDe} alt="Botón"/>Acerca de</Link>
                        <Link className="Sidebar-link" to="/privacidad"><img className="Sidebar-icon" src={iconPrivacidad} alt="Botón"/>Privacidad</Link>
                        <Link className="Sidebar-link" to="/buscar"><img className="Sidebar-icon" src={iconBuscar} alt="Botón"/>Buscar</Link>

                        <Link className="Sidebar-link-bottom" to="/iniciar-sesion"><img className="Sidebar-icon-bottom" src={iconIniciarSesion} alt="Botón"/>Entrar</Link>

                    </ul>
                    
                </div>

            </div>
        );
    }
}


export default SidebarMenu;