import React from 'react';
import ReactDOM from 'react-dom';

import 'bootstrap/dist/css/bootstrap.css';
import './global.css';
//Importamos para verificar el estado de autenticación
import './firebaseconf.js';

//Se encarga de manejar las vistas [para ser Single Pages App]
import App from './componentes/Main/App.js'
import AppDeUsuario from './componentes/SesionIniciada/AppDeUsuario.js'

import { getAuth, onAuthStateChanged } from "firebase/auth";
const auth = getAuth();

const container = document.getElementById('app');

const div = document.getElementById('onload');
const body = document.getElementById('body');
const loaderCircle = document.getElementById('loader-circle');
const loaderTextWeb = document.getElementById('loader-texto_web');

// Al cargar se cambia el contenido del html
window.onload = function() {

  var delayInMilliseconds = 2000; // 2 seconds

  // your code to be executed after 2 seconds
  setTimeout(function() {

    div.className = body.className.replace('fondo-carga', 'ocultar-fondo-carga');
    body.className = body.className.replace('ocultar-carga', '');
    loaderCircle.className = body.className.replace('loader-circle', 'hide');
    loaderTextWeb.className = body.className.replace('loader-texto_fuente-web', 'hide');

    div.style.animationDuration = '1s';
    div.style.height = "0vh";
    div.style.opacity = 0;
    

  }, delayInMilliseconds);

}


onAuthStateChanged(auth, (user) => {
    if (user) {

      
      // body.removeAttribute('ocultar-carga');

      // El usuario inició sesión
      ReactDOM.render(<AppDeUsuario/>, container);

    } else {

      // div.fadeOut();
      // body.removeAttribute('ocultar-carga');

      // El usuario cerró sesión
      ReactDOM.render(<App/>, container);
    }
});