import React from 'react'

import '../../global.css';
import '../estilos/BadgeMain.css';

import imagenLogo from '../../imagenes/iconos/icon_agendapdf_512px.png';

const banner = "https://images.unsplash.com/photo-1584433144859-1fc3ab64a957?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1930&q=80";

const imagenQueDatosNecesitaUnaCuenta = "https://images.unsplash.com/photo-1573166475912-1ed8b4f093d2?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=388&q=80";
const imagenMisDatos = "https://images.unsplash.com/photo-1562564055-71e051d33c19?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80";
// const imagen = "";


class Privacidad extends React.Component{

    render() {
        return (


            <React.Fragment>

                {/* Portada */}
                <div className="header-portada">
                    <img className="imagen-portada" src={banner} alt="Imagen de portada" />

                    <div className="header-contenido-portada">                        
                        <h1>Privacidad</h1>
                        <h2>Términos y condiciones</h2>
                    </div>
                </div>

                {/* Sección principal */}
                <div className="header-pantalla-completa">

                    <div className="header-media-pantalla">
                        <img src={imagenLogo} alt="Icono de my bio fans" style={{ height: '100%', width: '100%', padding:'0 25%' }}/>
                    </div>
                    
                    <div className="header-media-pantalla-blanca">
                        <h3>¿Qué tan seguro es AgendaPDF.com?</h3>
                        <br/>
                        <span className="span-texto-normal">Usamos servidores de Google para mantener seguros los datos todo el tiempo. Esto hace que nadie pueda hackear o vulnerar una cuenta.</span><br/>
                        <br/>
                        <span className="span-texto-normal">Además, tanto en la Página Web, como en la App para Android, la integración es 100% nativa. Así mantenemos tus datos siempre encriptados.</span><br/>
                        <br/>
                        <span className="span-texto-normal"><b>La principal característica de AgendaPDF es la productividad</b>.</span><br/>
                        <br/>
                        
                    </div>
                    
                </div>

                {/* Sección principal */}
                <div className="header-pantalla-completa">
                    
                    <div className="header-media-pantalla-blanca">
                        <h3>¿Qué datos necesita una cuenta?</h3>
                        <br/>
                        <span className="span-texto-normal"><b>Necesarios:</b></span><br/>
                        <span className="span-texto-normal">• Correo electrónico.</span><br/>
                        <span className="span-texto-normal">• Un <b>Nombre Público</b> (con el que decidas identificarte).</span><br/>
                        <span className="span-texto-normal">• Un <b>Nombre de Usuario</b> (con el que tu equipo de trabajo puedan buscarte).</span><br/>
                        <br/>
                        <span className="span-texto-normal"><b>Opcionales:</b></span><br/>
                        <span className="span-texto-normal">• Una biografía para describirte.</span><br/>
                        <span className="span-texto-normal">• Número de teléfono (para mejorar el trabajo en equipo y enviar mensajes de WhatsApp de forma rápida).</span><br/>
                        <br/>
                        <span className="span-texto-normal">Nota: <b>NUNCA vemos ni compartimos tu contraseña</b> (ésta se guarda de forma encriptada y ni el desarrollador tiene acceso a ella).</span><br/>
                        <br/>
                    </div>

                    <div className="header-media-pantalla">
                        <img className="imagen-media-pantalla" src={imagenQueDatosNecesitaUnaCuenta} alt="Imagen de Unsplash" style={{ height: '100%', width: '100%', maxHeight: '700px' , borderRadius:'100px', padding:'50px' }} />
                    </div>
                    
                </div>

                {/* Sección principal */}
                <div className="header-pantalla-completa">

                    <div className="header-media-pantalla">
                        <img className="imagen-media-pantalla" src={imagenMisDatos} alt="Imagen de Unsplash" style={{ height: '100%', width: '100%', borderRadius:'100px', padding:'50px' }} />
                    </div>
                    
                    <div className="header-media-pantalla-blanca">
                        <h3>¿Qué pasa con mis datos?</h3>
                        <br/>
                        <span className="span-texto-normal">Eres dueño de todos los datos que proporciones (foto de perfil, tareas, etc.).</span><br/>
                        <br/>
                        <span className="span-texto-normal">No recopilamos, vendemos, ni usamos tus datos con fines comerciales. Es una plataforma de uso libre. Sin letras pequeñas.</span><br/>
                        <br/>
                        <span className="span-texto-normal">Mira nuestra <a target="_blank" rel="noreferrer" href="https://adolfoordinola.notion.site/T-rminos-y-condiciones-Privacidad-833f08570e9e4c819c9cb331d180eaf8">Política de Privacidad completa</a>.</span><br/>
                        <br/>
                    </div>
                    
                </div>
                

            </React.Fragment>


        );
    }
}


export default Privacidad;