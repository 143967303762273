import React from 'react';

// import NavbarDeUsuario from './NavbarDeUsuario.js';
import NavbarSI from '../Navbar/NavbarSI.js'
import Footer from '../Footer/Footer.js';

import "../../global.css";

function Layout (props) {
    return (
        <React.Fragment>

            <NavbarSI />
            {props.children}
            <Footer />

        </React.Fragment>
    );
}

export default Layout;