import React from 'react';
import { Link } from 'react-router-dom';
import { useState } from 'react';

import './style.css';

import logoPrincipal from '../../imagenes/iconos/icon_agendapdf_512px.png';

import iconMenu from '../../imagenes/iconos/icon-menu.svg';

// Objetos personales
import SidebarCuenta from '../Sidebar/SidebarCuenta.js';

// ------------------------

const NavbarUI = ({ setIsOpen }) => (

    <nav className="Navbar">

        {/* Nombre de la página */}
        <Link className="Navbar__brand" to="/" title='Ir al inicio'>
            <img className="Navbar__brand-logo" src={logoPrincipal} alt="Logo" width="32" height="32"/>

            <span className='Navbar__fuente-bold'>Agenda</span>
            <span className="Navbar__fuente-light">PDF</span>
        </Link>

        <div className="lista-iconos">
            {/* Menú 1 */}
            <button onClick={() => setIsOpen(true)} title="Menú" className="navbar-icon"><img src={iconMenu} alt="Botón" width="20" height="20"/></button>
        </div>
    </nav>
);


const ModalDerecho = ({ isOpen, setIsOpen }) => (
    
    <div className={`modal-derecho ${isOpen ? "open" : ""}`} onClick={() => setIsOpen(false)}>
  
          <SidebarCuenta />
      
    </div>
);
const Overlay = ({ isOpen, setIsOpen }) => (
    <div className={`overlay ${isOpen ? "open" : ""}`} onClick={() => setIsOpen(false)}/>
);

function NavbarSI() {

    const [isOpen, setIsOpen] = useState(false);

    return (
        <div>

            {/* Navabar */}
            <NavbarUI setIsOpen={setIsOpen}/>

            <Overlay isOpen={isOpen} setIsOpen={setIsOpen}/>
            <ModalDerecho isOpen={isOpen} setIsOpen={setIsOpen}/>
        </div>
    );
  
};

export default NavbarSI;