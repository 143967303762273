import React from 'react';
import { Link } from 'react-router-dom';

import './style.css';

import iconEditarPerfil from '../../imagenes/iconos/icon-editar-perfil.svg';
import iconContactos from '../../imagenes/iconos/icon-contactos.svg';
import iconBuscar from '../../imagenes/iconos/icon-buscar.svg';

import iconPreferencias from '../../imagenes/iconos/icon-preferencias.svg';
import iconCerrarSesion from '../../imagenes/iconos/icon-cerrar-sesion.svg';

// ------------------------

// Importamos de Firebase
import { getAuth } from "firebase/auth";

const auth = getAuth();

// ------------------------

class SidebarCuenta extends React.Component {

    /* Datos iniciales */
    constructor(props) {
        super(props);

        this.handleCerrarSesion = this.handleCerrarSesion.bind(this);
    }

    /* Eventos */
    handleCerrarSesion = (e) => {
        e.preventDefault();

        // Se solicita confirmación
        const mensaje = `⚠ ¿Desea cerrar sesión?`;
        const isOK = window.confirm(mensaje);

        if(isOK){

            // Se cierra sesión
            auth.signOut().then(() => {
                // Sign-out successful.
                // console.log(mensaje);
                const mensaje = `Regresa pronto 👋🏼`;
                alert(mensaje);

                // Actualizamos la vista
                window.location.reload();

            }).catch((error) => {
                // An error happened.
                console.log("No se pudo cerrar sesión: " + error);
            });
            
        }
        
    }

    /* Lo que mostramos en pantalla */
    render(){
        return(

            <div className="Sidebar">

                <div className="Sidebar-menu" id='menu-completo'>

                    <ul className="Sidebar-list">

                        <p className="Sidebar__fuente">Cuenta</p>
                        <Link className="Sidebar-link" to="/editar-perfil"><img className="Sidebar-icon" src={iconEditarPerfil} alt="Botón"/>Editar perfil</Link>
                        <Link className="Sidebar-link" to="/contactos"><img className="Sidebar-icon" src={iconContactos} alt="Botón"/>Contactos</Link>
                        <Link className="Sidebar-link" to="/buscar"><img className="Sidebar-icon" src={iconBuscar} alt="Botón"/>Buscar cuenta</Link>

                        <p className="Sidebar__fuente">Otras funciones</p>
                        <Link className="Sidebar-link" to="/preferencias"><img className="Sidebar-icon" src={iconPreferencias} alt="Botón"/>Preferencias</Link>

                        <button className="Sidebar-link-bottom" onClick={this.handleCerrarSesion}><img className="Sidebar-icon-bottom" src={iconCerrarSesion} alt="Botón"/>Cerrar sesión</button>
                    </ul>
                    
                </div>

            </div>
        );
    }
}


export default SidebarCuenta;