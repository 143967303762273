export default class Tarea {

    constructor (uid, date, texto, leido) {

        this.uid = uid;
        this.date = date;
        this.texto = texto;
        this.leido = leido;
    }

    toString() {
        return this.uid + ', ' + this.date + ', ' + this.texto + ', ' + this.leido;
    }

    // Firestore data converter
    tareaConverter = {
        toFirestore: (tarea) => {
            return {
                uid: tarea.uid,
                date: tarea.date,
                texto: tarea.texto,
                leido: tarea.leido
            };
        },
        fromFirestore: (snapshot, options) => {
            const data = snapshot.data(options);
            return new Tarea(data.uid, data.date, data.texto, data.leido);
        }
    }
}