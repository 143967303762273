import React from 'react';

import '../global.css';
import '../componentes/estilos/Badge.css';
import BadgeBuscarCuenta from '../componentes/SesionIniciada/BadgeBuscarCuenta.js';

const banner = "https://images.unsplash.com/photo-1544383835-bda2bc66a55d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1136&q=80";

class BuscarCuenta extends React.Component {    


    state = { form: {

        nombrePublico: '',
        nombreUsuario: '',
    }};

    handleChange = e =>{

        this.setState({
            form: {
                ...this.state.form,
                [e.target.name]: e.target.value,
            },
            
        });
    };

    // -----------------------------

    /* Lo que mostramos en pantalla */
    render(){
        return(

            <React.Fragment>

                {/* Portada */}
                <div className="header-portada">
                    <img className="imagen-portada" src={banner} alt="Imagen de portada" />

                    <div className="header-contenido-portada">                        
                        <h1>Buscar</h1>
                    </div>
                </div>

                {/* Sección */}
                <BadgeBuscarCuenta/>

                {/* Sección */}
                <div className="header-pantalla-completa">                  

                    <div className='header-media-pantalla'>
                        <span className='span-centrado-light'>Buscar cuenta 🔍</span>
                        <span>AgendaPDF funciona mejor en equipos de trabajo. Busca el <b>nombre de usuario</b> de algún contacto para conectar con ellos.</span><br/>
                        <br/>
                        <span>Si aún no están en AgendaPDF.com, cuéntales sobre nosotros.</span><br/>
                        <br/>
                        <span>Todas las cuentas que agregues estarán en la sección de <b>Contactos</b>.</span><br/>
                    </div>

                </div>

            </React.Fragment>
        );
    }
                    
}


export default BuscarCuenta;