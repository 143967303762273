import React from 'react';

import "../../global.css";
import ".././estilos/Badge.css";
import header from '../../imagenes/iconos/icon_agregar_cuenta_256px.png';

import { Link } from 'react-router-dom';

//Escuchador del estado de sesión del usuario
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";

// Importamos los componentes a Firebase
import { doc, setDoc, getDoc } from "firebase/firestore";
import { getFirestore, collection, getDocs, query, where } from "firebase/firestore";

const db = getFirestore();

//Referencia del objeto Usuario
class Usuario {
    constructor (suscrito, verificado, nombrePublico, nombreUsuario,
        biografia, rutaFotoPerfil, clavePais, numeroTelefono) {

        this.suscrito = suscrito;
        this.verificado = verificado;
        this.nombrePublico = nombrePublico;
        this.nombreUsuario = nombreUsuario;

        this.biografia = biografia;
        this.rutaFotoPerfil = rutaFotoPerfil;

        this.clavePais = clavePais;
        this.numeroTelefono = numeroTelefono;
    }
    toString() {
        return this.suscrito + ', ' + this.verificado + ', ' + 
        this.nombrePublico + ', ' + this.nombreUsuario + ', ' + 
        this.biografia + ', ' + this.rutaFotoPerfil + ', ' +
        this.clavePais + ', ' + this.numeroTelefono;
    }
}

// Firestore data converter
const usuarioConverter = {
    toFirestore: (usuario) => {
        return {
            suscrito: usuario.suscrito,
            verificado: usuario.verificado,

            nombrePublico: usuario.nombrePublico,
            nombreUsuario: usuario.nombreUsuario,
            
            biografia: usuario.biografia,
            rutaFotoPerfil: usuario.rutaFotoPerfil,

            clavePais: usuario.clavePais,
            numeroTelefono: usuario.numeroTelefono
        };
    },
    fromFirestore: (snapshot, options) => {
        const data = snapshot.data(options);
        return new Usuario(data.suscrito, data.verificado, 
            data.nombrePublico, data.nombreUsuario,
            data.biografia, data.rutaFotoPerfil, 
            data.clavePais, data.numeroTelefono);
    }
};

// Declaro una variable de tipo Usuario.class
var mUsuario = new Usuario('','','','','','','','');

class BadgeCrearCuenta extends React.Component{
    

    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
     }
  
    handleSubmit = (e) => {

        e.preventDefault();
    
        const nombrePublico = e.target.elements.nombrePublico.value;
        const nombreUsuario = e.target.elements.nombreUsuario.value;
        
        const correo = e.target.elements.correo.value;
        const contraseña = e.target.elements.contraseña.value;

        // Antes de crear la cuenta, se verifica si ya existe el Nombre de Usuario
        const referenciaUsuarios = collection(db, "Usuarios");

        // Create a query against the collection.
        const q = query(
        referenciaUsuarios,
        where("nombreUsuario", "==", nombreUsuario)
        ).withConverter(usuarioConverter);
        const querySnapshot = getDocs(q);

        querySnapshot
        .then((snapshot) => {

            snapshot.forEach((doc) => {
                // doc.data() is never undefined for query doc snapshots

                // uidUsuario = doc.id;
                // mUsuario = doc.data();

                //Si el Objeto Usuario YA existe (Nombre de Usuario registrado)
                const mensajeUsuarioExistente = `El usuario @${nombreUsuario} ya existe.\n\nUtiliza otro nombre de usuario.`;
                alert(mensajeUsuarioExistente);

            });

            //Si el Objeto Usuario aún no existe (Nombre de Usuario no registrado)
            if (snapshot.empty) {

                // Crea el usuario
                const auth = getAuth();
                createUserWithEmailAndPassword(auth, correo, contraseña)
                .then((userCredential) => {
                    
                    // Signed in
                    const user = userCredential.user;

                    const mUid = user.uid;
                    async function funcionando() {

                        const ref = doc(db, "Usuarios", mUid).withConverter(usuarioConverter);
                        await setDoc(ref, new Usuario(
                            false,
                            false,
                            nombrePublico,
                            nombreUsuario,

                            "",
                            "",

                            "52",
                            ""
                            )
                        );
                        const docSnap =  await getDoc(ref);

                        if (docSnap.exists()) {

                            // Convert to Usuario object
                            mUsuario = docSnap.data();

                            // Use a Usuario instance method
                            // console.log("Usuario: " + mUsuario.toString());
                            alert(`\nYa tienes una cuenta 🎉 @${mUsuario.nombreUsuario} \n\n Aquí hay un par de cosas que puedes hacer:\n\n✅ Agregar datos a tu perfil\n✅ Comparte tu enlace para trabajar en equipo\n`);

                        } else {
                            console.log("No se pudo acceder");
                        }
                        
                        return Promise.resolve(docSnap);
                    
                    }

                    // Llamamos a la función
                    funcionando();

                })
                .catch((error) => {
                    const errorCode = error.code;
                    const errorMessage = error.message;

                    alert(`\nOcurrió un error: \n\n👉🏼 Tu Nombre Público únicamente pueden ser letras\n👉🏼 Tu Nombre de Usuario únicamente puede llevar letras, números, puntos y guiones bajos\n`);
                    console.log("Error: " + errorMessage, errorCode);
                });
            
            
            }

            return Promise.resolve(snapshot);
        })
        .catch((error) => {
            // An error happened.
            const errorCode = error.code;
            const errorMessage = error.message;

            console.log("No se pudo comprobar la existencia del usuario: " + errorCode + "\n\n" + errorMessage);
        });
            
    }

    // Lo que se visualiza en pantalla
    render(){
        //this.props;

        return(

            <div className="Badge_crear_cuenta">

                <div className="Badge__header__crear_cuenta">

                    <img className="Badge__header-icon_crear_cuenta" src={header} alt="Logo de página personal"/>
                    
                </div>


                <div className="Badge__section-name_crear_cuenta">
                    
            
                </div>

                <form onSubmit={this.handleSubmit} className="Badge__formulario">

                    <div className="formulario">
                        <label>Nombre público</label>
                        <input className="form-control" type="text"
                        name="nombrePublico" placeholder="Nombre y apellido (o marca)"/>
                    </div>

                    <div className="formulario">
                        <label>Nombre de usuario</label>
                        <input className="form-control" type="text" pattern="[A-Za-z0-9._]{5,20}" title="Un Nombre de Usuario válido consiste de 5 a 20 caracteres (puedes usar letras, números, punto o guión bajo)" required
                        name="nombreUsuario" placeholder="Sin el @"/>
                    </div>

                    <div className="formulario">
                        <label>Correo electrónico</label>
                        <input className="form-control" type="email" 
                        name="correo" placeholder="ejemplo@correo.com"/>
                    </div>

                    <div className="formulario">
                        <label>Contraseña</label>
                        <input className="form-control" type="password" pattern="[A-Za-z0-9._]{6,20}" title="Una Contraseña válida consiste de 6 a 20 caracteres (puedes usar letras, números, punto o guión bajo)" required
                        name="contraseña" placeholder="Al menos 6 caracteres"/>
                    </div>


                    <button className="btn btn-login" >Crear cuenta</button>

                </form>


                <br/>
                <Link className="link-simple" to="/iniciar-sesion">Ya tengo una cuenta</Link>

            </div>
            
        );

    }

}

//Como estamos en un archivo, debemos asegurarnos de exportar este componente
export default BadgeCrearCuenta;