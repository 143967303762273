import React from 'react';
import { Link } from 'react-router-dom';

import "../../global.css";
import "./estilos/BadgeSiguiendo.css";

// Importamos los componentes a Firebase
import { getFirestore, doc, getDoc } from "firebase/firestore";
// import { getAuth } from "firebase/auth";

import Usuario from './ObjetoUsuario.js';
import Siguiendo from './ObjetoSiguiendo.js';
import Textos from '../Textos.js';

//Iconos importados
// import iconWhatsApp from '../../imagenes/iconos/icon-whatsapp.svg';
// import iconTelefono from '../../imagenes/iconos/icon-telefono.svg';
// import iconCorreo from '../../imagenes/iconos/icon-correo.svg';


import imagenPrincipal from '../../imagenes/iconos/icon_iniciar_sesion_256px.png'
import iconCuentaVerificada from '../../imagenes/iconos/icon_usuario_verificado_128px.png'

// const auth = getAuth();
const db = getFirestore();

var mUid; // UID propia
var uidDeUsuario = ""; // UID ajena

// ------------------------

// Declaro una variable de tipo Usuario.class
var mUsuario = new Usuario('','','','','','','','');

// ------------------------


class BadgeSiguiendo extends React.Component{


    constructor(props) {
        super(props);


        // Cargamos el usuario actual
        // const user = auth.currentUser;


        // Se muestran datos provisionales
        this.state = {

            verificado: false,
        
            nombrePublico: "",
            nombreUsuario: "cargando...",
            
            biografia: mUsuario.biografia,
            rutaFotoPerfil: imagenPrincipal,

            clavePais: "",
            numeroTelefono: "",
            // correo: user.email,
        };

        mUid = this.props.idCreador;

        const referencia = doc(db, `Siguiendo/${mUid}/Personas`, this.props.uid).withConverter(Siguiendo.siguiendoConverter);
        const documentoSnap =  getDoc(referencia);

        documentoSnap.then((snapshot) => {

            
            // snapshot.id muestra el UID asociado al usuario en Siguiendo > miUID > Personas > UID
            if(snapshot.exists()){

                uidDeUsuario = this.props.uid;

                // Rellenamos datos
                // mUsuario = new Funciones().getUsuario(uidDeUsuario);

                // -----------

                const ref = doc(db, "Usuarios", uidDeUsuario).withConverter(Usuario.usuarioConverter);
                const docSnap =  getDoc(ref);

                docSnap.then((snapshot) => {

                    // Rellenamos datos
                    mUsuario = snapshot.data();

                    // -----------

                    this.setState(() => {                                
                        return {
                            verificado: mUsuario.verificado,
                            nombrePublico: mUsuario.nombrePublico,
                            nombreUsuario: mUsuario.nombreUsuario,
                                    
                            biografia: mUsuario.biografia,
                            rutaFotoPerfil: mUsuario.rutaFotoPerfil,

                            clavePais: mUsuario.clavePais,
                            numeroTelefono: mUsuario.numeroTelefono,
                            // correo: user.email
                        }
                    });

                    // Si mUsuario aún no tiene foto de perfil
                    if (mUsuario.rutaFotoPerfil === ""){            

                        this.setState(() => {
                            return {
                                rutaFotoPerfil: imagenPrincipal, // se muestra una provisional
                            }
                        });
                    }

                    
                    return Promise.resolve(docSnap);
                });
            }


            if(snapshot.empty){

                //No se devuelve un Objeto Usuario
                uidDeUsuario = "";
                this.setState(() => {
                            
                    return {
                        verificado: false,
                        nombrePublico: "",
                        nombreUsuario: "",
                                    
                        biografia: `Al parecer aún no sigues a nadie 🙁`,
                        rutaFotoPerfil: imagenPrincipal, // se muestra una provisional
                            
                        clavePais: "",
                        numeroTelefono: ""
                    }
                });

            }


            if(!snapshot.exists()){

                //No se devuelve un Objeto Usuario
                uidDeUsuario = "";
                this.setState(() => {
                            
                    return {
                        verificado: false,
                        nombrePublico: "",
                        nombreUsuario: "usuario",
                                    
                        biografia: `Al parecer aún no sigues a nadie 🙁`,
                        rutaFotoPerfil: imagenPrincipal, // se muestra una provisional
                            
                        clavePais: "",
                        numeroTelefono: ""
                    }
                });
                
            }            
            
            return Promise.resolve(documentoSnap);
        });


        // ----------------------------


        
    
        // this.handleMostrarDatos = this.handleMostrarDatos.bind(this);
        this.handleMensajeCuentaVerificada = this.handleMensajeCuentaVerificada.bind(this);
        this.handleEscribirComentario = this.handleEscribirComentario.bind(this);
    }

    handleMensajeCuentaVerificada = (e) => {

        e.preventDefault();

        const mensaje = new Textos().usuarioVerificado();
        alert(mensaje);        
    }

    handleEscribirComentario = (e) => {

        e.preventDefault();

        // const mensaje = `Enviando... a @${this.state.nombreUsuario} 🤫`;
        // alert(mensaje);

        // onClick={this.handleEscribirComentario}
    }


    /* Lo que se muestra en pantalla */
    render(){
        

        return(

            <div className="BadgeSiguiendo">

                {/* Header */}
                <div className="BadgeSiguiendo__header">
                    <img className="icon-verificado" src={iconCuentaVerificada} hidden={!this.state.verificado} onClick={this.handleMensajeCuentaVerificada} alt="Estado de cuenta verificada"/>
                </div>

                {/* Foto y nombres de perfil */}
                <div className="BadgeSiguiendo__section-name">
                    
                    <div className="imagen-avatar-mini">
                        <img src={this.state.rutaFotoPerfil} alt="Imagen de perfil"/>
                    </div>

                    <h5> {this.state.nombrePublico} </h5>
                    <h6> @{this.state.nombreUsuario} </h6>
    
                </div>

                {/* Biografía */}
                <div hidden={!this.state.biografia} className="BadgeSiguiendo__section-biografia">
                    {this.state.biografia}        
                </div>

                {/* Ver usuario */}
                <div className="BadgeSiguiendo__icons">
                    <Link to={"/"+this.state.nombreUsuario} className="btn btn-escribir-comentario">Ver</Link>
                </div>

            </div>
            
        );

    }

}

//Como estamos en un archivo, debemos asegurarnos de exportar este componente
export default BadgeSiguiendo;