import React from 'react';

import '../global.css';
import imagenPrincipal from '../imagenes/inicio-door-hub.svg'

function NotFound() {

    return (

        <React.Fragment>

                <div className="header-pantalla-completa">

                    <div className="header-media-pantalla">
                        <img className="header-pantalla-completa" src={imagenPrincipal} alt="Imagen de inicio" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '70%', width: '70%', padding: '5rem 0 0 0' }}/>
                    </div>
                    
                    <div className="header-media-pantalla">
                        <h1>ERROR 404</h1> 
                        <h2>Página no encontrada 🙁</h2>
                        <br/>
                        <a className="btn" href="/">Ir al inicio</a>
                    </div>

                </div>


            </React.Fragment>


    );
}

export default NotFound;