import React from 'react'
import { Link } from 'react-router-dom';

import '../../global.css';
import '../estilos/BadgeMain.css';

import imagenLogo from '../../imagenes/iconos/icon_agendapdf_512px.png';
import imagenFortinTech from '../../imagenes/FortinTech.png';

const banner = "https://images.unsplash.com/photo-1499914567823-c240485cb7d1?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1770&q=80";

const imagenQuePasaConMisDatos = "https://images.unsplash.com/photo-1446511437394-36cdff3ae1b3?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80";
const imagenPorqueNecesitoUnaCuenta = "https://images.unsplash.com/photo-1606189934732-1c274f894bf9?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80";
const imagenFuncionesEnCamino = "https://images.unsplash.com/photo-1599420186946-7b6fb4e297f0?ixlib=rb-1.2.1&ixid=MnwxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80";
// const imagen = "";


class AcercaDe extends React.Component{

    render() {
        return (


            <React.Fragment>

                {/* Portada */}
                <div className="header-portada">
                    <img className="imagen-portada" src={banner} alt="Imagen de portada" />

                    <div className="header-contenido-portada">                        
                        <h1>Acerca de</h1>
                        <h2>Preguntas frecuentes</h2>
                    </div>
                </div>

                {/* Sección principal */}
                <div className="header-pantalla-completa">

                    <div className="header-media-pantalla">
                        <img src={imagenLogo} alt="Icono de my bio fans" style={{ height: '100%', width: '100%', padding:'0 25%' }}/>
                    </div>
                    
                    <div className="header-media-pantalla-blanca">
                        <h3>¿Qué es AgendaPDF.com?</h3>
                        <br/>
                        <span className="span-texto-normal">Es una alternativa a calendarios online que te permite imprimir tu itinerario de "cosas por hacer".</span><br/>
                        <br/>
                        <span className="span-texto-normal">¿Porqué se imprime? Porque se ha comprobado científicamente que resulta más productivo escribir sobre papel.</span><br/>
                        <br/>
                        <span className="span-texto-normal">Por eso, <b>la principal característica de AgendaPDF es la productividad</b>.</span><br/>
                        <br/>
                        
                    </div>
                    
                </div>

                {/* Sección principal */}
                <div className="header-pantalla-completa">
                    
                    <div className="header-media-pantalla-blanca">
                        <h3>¿Qué pasa con mis datos?</h3>
                        <br/>
                        <span className="span-texto-normal">Todos los datos que proporciones (foto de perfil, tareas, etc.) son almacenados en servidores seguros respaldados por Google.</span><br/>
                        <br/>
                        <span className="span-texto-normal">No recopilamos, vendemos, ni usamos tus datos con fines comerciales. Es una plataforma de uso libre. Sin letras pequeñas.</span><br/>
                        <br/>
                        <span className="span-texto-normal">En otras palabras, la plataforma se creó para poder potenciar la productividad (siempre puedes ver nuestra política de <Link to="/privacidad">Términos y Condiciones</Link> 🔐).</span><br/>
                        <br/>
                    </div>

                    <div className="header-media-pantalla">
                        <img className="imagen-media-pantalla" src={imagenQuePasaConMisDatos} alt="Imagen de Unsplash" style={{ height: '100%', width: '100%', borderRadius:'100px', padding:'50px' }} />
                    </div>
                    
                </div>

                {/* Sección principal */}
                <div className="header-pantalla-completa">

                    <div className="header-media-pantalla">
                        <img className="imagen-media-pantalla" src={imagenPorqueNecesitoUnaCuenta} alt="Imagen de Unsplash" style={{ height: '100%', width: '100%', borderRadius:'100px', padding:'50px' }} />
                    </div>
                    
                    <div className="header-media-pantalla-blanca">
                        <h3>¿Porqué necesito crear una cuenta?</h3>
                        <br/>
                        <span className="span-texto-normal">Para acceder, es necesario iniciar sesión, esto es para que puedas acceder a tus datos desde cualquier lugar.</span><br/>
                        <br/>
                        <span className="span-texto-normal">• Puedes cambiar tu <b>Nombre Público</b> siempre que quieras.</span><br/>
                        <span className="span-texto-normal">• Recomendamos que tu <b>Nombre de Usuario</b> sea corto.</span><br/>
                        <br/>
                        <span className="span-texto-normal"><b>NUNCA vemos ni compartimos tu contraseña</b> (ésta se guarda de forma encriptada y ni el desarrollador tiene acceso a ella).</span><br/>
                        <br/>
                    </div>
                    
                </div>

                {/* Sección principal */}
                <div className="header-pantalla-completa">
                    
                    <div className="header-media-pantalla-blanca">
                        <h3>¿Qué funciones vienen en camino?</h3>
                        <br/>
                        <span className="span-texto-normal">De momento la plataforma es simple, para que todos se concentren en lo principal: <b>crear tareas e imprimirlas</b>.</span><br/>
                        <br/>
                        <span className="span-texto-normal">Fuera de ésta página web, hay una app para Android en desarrollo.</span><br/>
                        <br/>
                        <span className="span-texto-normal">Para proponer cambios o funciones nuevas sígueme en <a target="_blank" rel="noreferrer" href="https://twitter.com/intent/user?screen_name=AdolfoOrdinola">Twitter</a>.</span><br/>
                        <br/>
                    </div>

                    <div className="header-media-pantalla">
                        <img className="imagen-media-pantalla" src={imagenFuncionesEnCamino} alt="Imagen de Unsplash" style={{ aspectRatio: '1', height: '100%', width: '100%', borderRadius:'130px', padding:'80px', objectFit: 'cover' }} />
                    </div>
                    
                </div>

                {/* Sección principal */}
                <div className="header-pantalla-completa">

                    <div className="header-media-pantalla">
                        <img className="imagen-media-pantalla" src={imagenFortinTech} alt="Imagen de Unsplash" style={{ height: '100%', width: '100%', borderRadius:'130px', padding:'80px' }} />
                    </div>
                    
                    <div className="header-media-pantalla-blanca">
                        <h3>¿Quiénes crearon AgendaPDF?</h3>
                        <br/>
                        <span className="span-texto-normal"><b>Fortín Tech.</b></span><br/>
                        <br/>
                        <span className="span-texto-normal">Toda la planeación y desarrollo ha sido por la Familia Pérez Ordinola bajo la marca <Link to="/fortin-tech">Fortín Tech</Link>.</span><br/>
                        <br/>
                        <span className="span-texto-normal">Puedes apoyar el crecimiento de AgendaPDF contándole a tus amigos o compañeros de escuela y trabajo ❤.</span><br/>
                        <br/>
                    </div>
                    
                </div>


            </React.Fragment>


        );
    }
}


export default AcercaDe;