import React from 'react';
import { Link } from 'react-router-dom';
import { useState } from 'react';

import './style.css';

import logoPrincipal from '../../imagenes/iconos/icon_agendapdf_512px.png';

import iconMenu from '../../imagenes/iconos/icon-menu.svg';

import Sidebar from '../Sidebar/SidebarMenu.js';


// ------------------------

const NavbarUI = ({ setIsOpen }) => (

    <nav className="Navbar">

        {/* <Link className="Navbar__brand" to="/" title='Ir al inicio'>
              <img className="Navbar__brand-logo" src={logoPrincipal} alt="Logo" width="32" height="32"/>
            </Link> */}

        <Link className="Navbar__brand" to="/" title='Ir al inicio'>
            <img className="Navbar__brand-logo" src={logoPrincipal} alt="Logo" width="32" height="32"/>

            <span className='Navbar__fuente-bold'>Agenda</span>
            <span className="Navbar__fuente-light">PDF</span>
        </Link>

        <div className="lista-iconos">
            <Link className="link-menu" to="/acerca-de">Acerca de</Link>
            <Link className="link-menu" to="/privacidad">Privacidad</Link>
            <Link className="link-menu" to="/buscar">Buscar</Link>
            <button className="navbar-icon-oculto" onClick={() => setIsOpen(true)} title="Menú"><img src={iconMenu} alt="Botón" width="20" height="20"/></button>
        </div>
    </nav>
);

const Overlay = ({ isOpen, setIsOpen }) => (

  <div className={`overlay ${isOpen ? "open" : ""}`} onClick={() => setIsOpen(false)} />

);

const ModalDerecho = ({ isOpen , setIsOpen }) => (
    
    <div className={`modal-derecho ${isOpen ? "open" : ""}`} onClick={() => setIsOpen(false)}>
        
        <Sidebar />
      
    </div>
);


function Navbar() {

  const [isOpen, setIsOpen] = useState(false);

  return (
      <div>

          {/* Navabar */}
          <NavbarUI setIsOpen={setIsOpen} />
          <Overlay isOpen={isOpen} setIsOpen={setIsOpen}/>

          <ModalDerecho isOpen={isOpen} setIsOpen={setIsOpen}/>
      </div>
  );

};

export default Navbar;