import React from 'react';

import "../../global.css";
import "../estilos/Badge.css";
import header from '../../imagenes/iconos/icon_iniciar_sesion_256px.png';
import { Link } from 'react-router-dom';

//Escuchador del estado de sesión del usuario
// import { verifyPasswordResetCode, confirmPasswordReset } from "firebase/auth";
// import { getAuth, sendPasswordResetEmail, signInWithEmailAndPassword } from "firebase/auth";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";

const auth = getAuth();


class BadgeRecuperarCuenta extends React.Component{

    /* handleResetPassword(auth, actionCode, continueUrl, lang) {
        // Localize the UI to the selected language as determined by the lang
        // parameter.
      
        // Verify the password reset code is valid.
        verifyPasswordResetCode(auth, actionCode).then((email) => {
          const accountEmail = email;
      
          // Muestra la pantalla de reinicio con el correo electrónico del usuario 
          // y solicita al usuario la nueva contraseña.
          const newPassword = "...";
      
          // Guarda la nueva password.
          confirmPasswordReset(auth, actionCode, newPassword).then((resp) => {
            // Se ha confirmado el restablecimiento de la contraseña y se ha actualizado la nueva contraseña.
      
            // Muestre un enlace a la aplicación o inicie sesión directamente con el usuario 
            // si la página pertenece al mismo dominio que la aplicación:
            // auth.signInWithEmailAndPassword(accountEmail, newPassword);
      
            // Si hay una URL de continuación disponible, muestre un botón que, al hacer clic, 
            // redirige al usuario a la aplicación a través de continueUrl con un 
            // estado adicional determinado a partir de los parámetros de esa URL.
    
          }).catch((error) => {
            // Ocurrió un error durante la confirmación. 
            // Es posible que el código haya caducado o que la contraseña sea demasiado débil.
          });
        }).catch((error) => {
          // Código de acción no válido o caducado. 
          // Pida al usuario que intente restablecer la contraseña nuevamente.
        });
    } */


    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
     }
  
    // Botón para iniciar sesión
    handleSubmit = (e) => {

        e.preventDefault();
    
        const correo = e.target.elements.correo.value;
    
        if(correo!=null){

            const mensaje = `Se ha enviado un correo para reiniciar tu contraseña. \n\nRevisa tu bandeja de entrada (y la de SPAM o no deseados) de ${correo}`;

            sendPasswordResetEmail(auth, correo)
            .then(() => {
                // Sign-out successful.
                // console.log(mensaje);
                alert(mensaje);
            }).catch((error) => {
                // An error happened.
                const errorCode = error.code;
                const errorMessage = error.message;
                console.log("No se pudo reiniciar la contraseña: " + errorCode + "\n\n" + errorMessage);
            });

        } else{

            const mensaje = `Escribe tu correo en el campo de "Correo electrónico"`;
            alert(mensaje);

        }
            
    }


    // Lo que se visualiza en pantalla
    render(){
        //this.props;

        return(

            <div className="Badge_crear_cuenta">

                <div className="Badge__header__crear_cuenta">

                    <img className="Badge__header-icon_crear_cuenta" src={header} alt="Logo de página personal"/>
                    
                </div>


                <div className="Badge__section-name_crear_cuenta">
                    
                
                    <div className="contenedor-imagen-avatar">
                        <img src={this.props.avatarUrl} alt="Imagen de perfil"/>
                    </div>
                    
                    <h5> AgendaPDF </h5>
                    <h6> @usuario </h6>
            
                </div>

                <form onSubmit={this.handleSubmit} className="Badge__formulario">


                    <div className="formulario">
                        <label>Correo electrónico</label>
                        <input className="form-control" type="email" id="correo" onSubmit={this.recuperarPassword}
                        name="correo" placeholder="ejemplo@correo.com"/>
                    </div>

                    <button className="btn btn-login" >Recuperar cuenta</button>

                </form>

                <br/>
                <Link className="link-simple" to="/iniciar-sesion">Regresar</Link>

                

            </div>
            
        );

    }

}

//Como estamos en un archivo, debemos asegurarnos de exportar este componente
export default BadgeRecuperarCuenta;