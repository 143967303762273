import React from 'react'

import '../global.css';
import Usuario from '../componentes/SesionIniciada/ObjetoUsuario.js';
import BadgeEditarPerfil from '../componentes/SesionIniciada/BadgeEditarPerfil.js';

import { getAuth /*, onAuthStateChanged */ } from 'firebase/auth';
import { getFirestore, doc, getDoc } from 'firebase/firestore';

const auth = getAuth();
const db = getFirestore();

// import { Link } from 'react-router-dom';

// ------------------------

// Declaro una variable de tipo Usuario.class
var mUsuario = new Usuario('','','','','','','','');

// ------------------------

/*  onAuthStateChanged(auth, (user) => {
    if (user) {
      
        // https://firebase.google.com/docs/reference/js/firebase.User

        const mUid = auth.currentUser.uid;
        async function funcionando() {

            const ref = doc(db, "Usuarios", mUid).withConverter(Usuario.usuarioConverter);
            const docSnap =  await getDoc(ref);

            if (docSnap.exists()) {

                // Convert to Usuario object
                mUsuario = docSnap.data();

                // Use a Usuario instance method
                // console.log("Usuario: " + mUsuario.toString());

            } else {
                console.log("No se pudo acceder");
            }
            
            return Promise.resolve(docSnap);
        }

        // Llamamos a la función
        funcionando();

    }
});  */

class EditarPerfil extends React.Component{

    constructor(props) {
        super(props);

        this.state = {
            mUsuario: mUsuario
        };

        // Si mUsuario NO TIENE datos (se solicitan a la BD)
        if (mUsuario.nombrePublico === ""){

            const mUid = auth.currentUser.uid;
            const ref = doc(db, "Usuarios", mUid).withConverter(Usuario.usuarioConverter);
            const docSnap =  getDoc(ref);

            // Se piden los datos
            docSnap.then((snapshot) => {
                
                // Se rellenan los datos
                mUsuario = snapshot.data();

                // Se actualiza el estado
                this.setState(() => {
                    return {
                        mUsuario: mUsuario
                    }
                });
                    
                // return Promise.resolve(docSnap);
            });

        }
    }

    /* Datos iniciales */
    /* state = { form: {

        suscrito: mUsuario.suscrito,
        verificado: mUsuario.verificado,

        nombrePublico: mUsuario.nombrePublico,
        nombreUsuario: mUsuario.nombreUsuario,
        
        biografia: mUsuario.biografia,
        rutaFotoPerfil: mUsuario.rutaFotoPerfil,

        clavePais: mUsuario.clavePais,
        numeroTelefono: mUsuario.numeroTelefono,
        // correo: usuario.email,
        
    }}; */

    /* Eventos */
    /* handleChange = e =>{

        this.setState({  
            form: {
                ...this.state.form,
                [e.target.name]: e.target.value,
            },
            
        });
    }; */

    handleChange = e =>{

        this.setState(() => {  
            return {
                ...this.state,
                [e.target.name]: e.target.value,
            }            
        });
    };

    /* Lo que mostramos en pantalla */
    render() {
        return (


            <React.Fragment>


                <div className="header-pantalla-completa">
                    
                    <div className="header-media-pantalla">

                        <span className='span-centrado-light'>Edita tu perfil</span>
                        <span>Tu <b>Nombre Público</b> es con el que apareces en AgendaPDF.</span><br/>
                        <br/>
                        <span>Puedes agregar una breve biografía para que te reconozcan tus contactos. Incluso, puedes agregar tu número de teléfono.</span><br/>
                        <br/>
                        <span>Recuerda que todos tus datos siempre están protegidos con servidores de Google 🔐</span><br/>
                        <br/>
                        <br/>
                        
                    </div>
                    
                    <div className="header-media-pantalla">
                        
                        <BadgeEditarPerfil
                            suscrito={this.state.mUsuario.suscrito}
                            verificado={this.state.mUsuario.verificado}

                            nombrePublico={this.state.mUsuario.nombrePublico}
                            nombreUsuario={this.state.mUsuario.nombreUsuario}

                            rutaFotoPerfil={this.state.mUsuario.rutaFotoPerfil}
                            biografia={this.state.mUsuario.biografia}

                            clavePais={this.state.mUsuario.clavePais}
                            numeroTelefono={this.state.mUsuario.numeroTelefono}
                        />
                        
                    </div>

                </div>


            </React.Fragment>


        );
    }
}


export default EditarPerfil;