import React from 'react';

import "../global.css";
import "./estilos/TarjetaPerfil.css";
// import header from '../imagenes/iconos/icon_mybio_fans_512px.png';

//Iconos importados
import iconWhatsApp from '../imagenes/iconos/icon-whatsapp.svg';
import iconTelefono from '../imagenes/iconos/icon-telefono.svg';
import iconCorreo from '../imagenes/iconos/icon-correo.svg';

// import iconFacebook from '../imagenes/iconos/icon-facebook.svg';
// import iconTelegram from '../imagenes/iconos/icon-telegram.svg';
// import iconPaypal from '../imagenes/iconos/icon-paypal.svg';

import iconCuentaVerificada from '../imagenes/iconos/icon_usuario_verificado_128px.png';
import Textos from './Textos.js';


class TarjetaPerfil extends React.Component{

    constructor(props) {
        super(props);
    
        this.handleMensajeCuentaVerificada = this.handleMensajeCuentaVerificada.bind(this);
    }


    // EVENTOS ---------------

    handleMensajeCuentaVerificada = (e) => {
        e.preventDefault();

        const mensaje = new Textos().usuarioVerificado();
        alert(mensaje);        
    }

    
    render(){
        

        return(

            <div className="TarjetaPerfil">

                <div className="TarjetaPerfil__header">
                    <img className="icon-verificado" src={iconCuentaVerificada} hidden={!this.props.verificado} onClick={this.handleMensajeCuentaVerificada} alt="Estado de cuenta verificada"/>
                </div>

                <div className="TarjetaPerfil__section-name">
                    
                    <div hidden={!this.props.rutaFotoPerfil} className="imagen-avatar">
                        <img src={this.props.rutaFotoPerfil} alt=""/>
                    </div>
                    
                    <h5> {this.props.nombrePublico} </h5>
                    <h4> @{this.props.nombreUsuario} </h4>
            
                </div>

                <div hidden={!this.props.biografia} className="TarjetaPerfil__section-biografia">
                    {this.props.biografia}
                </div>

                <div hidden={!this.props.permisosDeContacto} className="TarjetaPerfil__icons"> 
                    <a hidden={!this.props.numeroTelefono} href={"https://wa.me/" + this.props.clavePais + this.props.numeroTelefono} target='_blank' rel='noreferrer' title="Mandar WhatsApp"> <img className="icon" src={iconWhatsApp} alt="Icono de whatsapp"/> </a>
                    <a hidden={!this.props.numeroTelefono} href={"tel:" + this.props.numeroTelefono} target='_blank' rel='noreferrer' title="Llamar por teléfono"> <img className="icon" src={iconTelefono} alt="Icono de teléfono"/> </a>
                    <a hidden={!this.props.correo} href={"mailto:" + this.props.correo} target='_blank' rel='noreferrer' title="Enviar correo"><img className="icon" src={iconCorreo} alt="Icono de correo" /> </a>
                </div>

            </div>
            
        );

    }

}

//Como estamos en un archivo, debemos asegurarnos de exportar este componente
export default TarjetaPerfil;