import React from 'react';

import Navbar from '../Navbar/Navbar.js';
import Footer from '../Footer/Footer.js';

function Layout (props) {
    
    return (
        
        <React.Fragment>

            <Navbar />            
            {props.children}
            <Footer />

        </React.Fragment>
    );
}

export default Layout;