import React from 'react';


// Importamos los componentes a Firebase
import { getFirestore, collection, getDocs, query, where } from "firebase/firestore";

// const referenciaUsuarios = collection(db, "Usuarios");

import BadgeCuentaEncontrada from '../SesionIniciada/BadgeCuentaEncontrada.js';
import Usuario from '../SesionIniciada/ObjetoUsuario.js'

import "../../global.css";
import "../estilos/Badge.css";

import imagenPrincipal from '../../imagenes/iconos/icon_iniciar_sesion_256px.png'

const db = getFirestore();

var uidUsuario = "";

// Declaro una variable de tipo Usuario.class
var mUsuario = new Usuario('','','','','','','','');

// ------------------------

class BadgeBuscarCuenta extends React.Component{

    /* handleResetPassword(auth, actionCode, continueUrl, lang) {
        // Localize the UI to the selected language as determined by the lang
        // parameter.
      
        // Verify the password reset code is valid.
        verifyPasswordResetCode(auth, actionCode).then((email) => {
          const accountEmail = email;
      
          // Muestra la pantalla de reinicio con el correo electrónico del usuario 
          // y solicita al usuario la nueva contraseña.
          const newPassword = "...";
      
          // Guarda la nueva password.
          confirmPasswordReset(auth, actionCode, newPassword).then((resp) => {
            // Se ha confirmado el restablecimiento de la contraseña y se ha actualizado la nueva contraseña.
      
            // Muestre un enlace a la aplicación o inicie sesión directamente con el usuario 
            // si la página pertenece al mismo dominio que la aplicación:
            // auth.signInWithEmailAndPassword(accountEmail, newPassword);
      
            // Si hay una URL de continuación disponible, muestre un botón que, al hacer clic, 
            // redirige al usuario a la aplicación a través de continueUrl con un 
            // estado adicional determinado a partir de los parámetros de esa URL.
    
          }).catch((error) => {
            // Ocurrió un error durante la confirmación. 
            // Es posible que el código haya caducado o que la contraseña sea demasiado débil.
          });
        }).catch((error) => {
          // Código de acción no válido o caducado. 
          // Pida al usuario que intente restablecer la contraseña nuevamente.
        });
    } */

    constructor(props) {
        super(props);

        // Si ya hay datos
        if (mUsuario.nombrePublico!==null && mUsuario.nombrePublico!==""){

            // Se muestran
            this.state = {

                verificado: mUsuario.verificado,
                nombrePublico: mUsuario.nombrePublico,
                nombreUsuario: mUsuario.nombreUsuario,
                
                biografia: mUsuario.biografia,
                rutaFotoPerfil: mUsuario.rutaFotoPerfil,
        
                clavePais: mUsuario.clavePais,
                numeroTelefono: mUsuario.numeroTelefono,
            };

        } else { // Si no

            // Se muestran datos provisionales
            this.state = {

                verificado: false,
                nombrePublico: "",
                nombreUsuario: "usuario",
                
                biografia: "",
                rutaFotoPerfil: imagenPrincipal,
        
                clavePais: mUsuario.clavePais,
                numeroTelefono: mUsuario.numeroTelefono,
            };
        }
        
        this.handleSubmit = this.handleSubmit.bind(this);
    }

  
    // Botón para Buscar Cuenta
    handleSubmit = (e) => {
        e.preventDefault();
    
        var userName = e.target.elements.userName.value;
        
    
        if(userName!==null && userName!==""){

            if(userName.includes("https://agendapdf.com/")){
                userName = userName.replace("https://agendapdf.com/", "");
            } else if(userName.includes("agendapdf.com/")){
                userName = userName.replace("agendapdf.com/", "");
            } else if(userName.includes("@")){
                userName = userName.replace("@", "");
            }

            const referenciaUsuarios = collection(db, "Usuarios");
            const q = query(referenciaUsuarios, where("nombreUsuario", "==", userName)).withConverter(mUsuario.usuarioConverter);
            const querySnapshot = getDocs(q);

            querySnapshot
            .then((snapshot) => {

                snapshot.forEach((doc) => {

                    // doc.data() is never undefined for query doc snapshots
                    
                    // console.log(doc.id, " => ", doc.data());
                    uidUsuario = doc.id;
                    mUsuario = doc.data();

                    this.setState(() => {                                
                        return {
                            verificado: mUsuario.verificado,
                            nombrePublico: mUsuario.nombrePublico,
                            nombreUsuario: mUsuario.nombreUsuario,
                                    
                            biografia: mUsuario.biografia,
                        }
                    });

                    // Si mUsuario ya tiene foto de perfil
                    if (mUsuario.rutaFotoPerfil !== ""){

                            this.setState(() => {                                
                                return {
                                    rutaFotoPerfil: mUsuario.rutaFotoPerfil,
                                }
                            });
                
                    } else { // Si mUsuario aún no tiene foto de perfil

                            this.setState(() => {                                
                                return {
                                    rutaFotoPerfil: imagenPrincipal, // se muestra una provisional
                                }
                            });
                            
                    }
    
                });

                if(snapshot.empty){

                    //No se devuelve un Objeto Usuario
                    uidUsuario = "";
                    this.setState(() => {
                                
                        return {

                            verificado: false,
                            nombrePublico: "",
                            nombreUsuario: "Error!",
                                        
                            biografia: `Al parecer el usuario @${userName} aún no existe`,
                            rutaFotoPerfil: imagenPrincipal, // se muestra una provisional
                        }
                    });

                }

            })
            .catch((error) => {
                // An error happened.
                const errorCode = error.code;
                const errorMessage = error.message;

                console.log("No se pudo realizar la búsqueda: " + errorCode + "\n\n" + errorMessage);
            });


        } else{

            const mensaje = `Escriba un nombre de usuario para buscarlo en AgendaPDF`;
            alert(mensaje);

        }

            
    }


    // -----------------------------

    /* Lo que mostramos en pantalla */
    render(){
        //this.props;

        return(

            <div className='header-pantalla-completa'>

                <div className='header-media-pantalla'>


                    <form onSubmit={this.handleSubmit} className="Badge__formulario">


                        <div className="formulario">
                            <label>Nombre de usuario</label>
                            <input className="Badge__input" type="text" id="userName"
                            name="userName" placeholder="@usuario"/>
                        </div>

                        <button className="Badge__boton-buscar">Buscar</button>

                    </form>

                </div>


                <div className='header-media-pantalla'>
                    <BadgeCuentaEncontrada
                        uidUsuario={uidUsuario}
                        
                        verificado={this.state.verificado}
                        nombrePublico={this.state.nombrePublico}
                        nombreUsuario={this.state.nombreUsuario}

                        rutaFotoPerfil={this.state.rutaFotoPerfil}
                        biografia={this.state.biografia}
                    />
                </div>


                
            </div>
            
        );

    }

}

//Como estamos en un archivo, debemos asegurarnos de exportar este componente
export default BadgeBuscarCuenta;