import React from 'react';
import { Link } from 'react-router-dom';

import '../../global.css';
import '../../componentes/estilos/Badge.css';
import BadgeIniciarSesion from '../../componentes/SesionIniciada/BadgeIniciarSesion.js';

const banner = "https://images.unsplash.com/photo-1592890288564-76628a30a657?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1770&q=80";

class IniciarSesion extends React.Component {    


    state = { form: {

        nombrePublico: '',
        nombreUsuario: '',
        correo: '',
        contraseña: '',
    }};

    handleChange = e =>{

        /*1er Forma de conservar la persistencia
        const nextForm = this.state.form;
        nextForm[e.target.name] = e.target.value;*/

        //1er Forma de conservar la persistencia
        //this.setState(form: nextForm,);

        //2da Forma de conservar la persistencia

        //Dejo caer todos los valores del form anteriormente
        //Y sobreescribimos el que ya estaba guardado

        this.setState({  
            form: {
                ...this.state.form,
                [e.target.name]: e.target.value,
            },
            
        });
    };


    render(){
        return(

            <React.Fragment>
                
                <div className="header-portada">                
                    <img className="imagen-portada" src={banner} alt="Imagen de portada"/>
                </div>

                <div className="header-pantalla-completa">

                    <div className='header-media-pantalla'>
                        <span className='span-centrado'>Iniciar sesión 🔐</span>
                        <span>Si ya tienes una cuenta en AgendaPDF, accede con tu correo y contraseña. Esta es una forma fácil y rápida de acceder a todos tus datos.</span><br/>
                        <br/>
                        <span>Si olvidaste tu contraseña puedes recuperar tu cuenta en segundos.</span><br/>
                        <br/>
                        <span>Al iniciar sesión aceptas los <Link to="/privacidad">términos y condiciones</Link> de privacidad.</span><br/>
                    </div>

                    <div className="header-media-pantalla">

                            <br/>        
                            <BadgeIniciarSesion 
                                nombreUsuario={this.state.form.nombreUsuario}
                                nombrePublico={this.state.form.nombrePublico}
                                        
                                correo={this.state.form.correo}
                                contraseña={this.state.form.contraseña}/>
                            <br/>        
                                        
                    </div>
                </div>

            </React.Fragment>
        );
    }
                    
}


export default IniciarSesion;