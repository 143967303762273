// Importamos Firebase
import { initializeApp } from 'firebase/app';
// import { getAnalytics } from "firebase/analytics";
// import { getAuth } from "firebase/auth";

// Configuración de Firebase
const firebaseConfig = {
  apiKey: "AIzaSyBXWqx5n9sCpuTuPMnSu5h0WNtdTpDJATc",
  authDomain: "agendadiaria-pdf.firebaseapp.com",
  projectId: "agendadiaria-pdf",
  storageBucket: "agendadiaria-pdf.appspot.com",
  messagingSenderId: "926895441130",
  appId: "1:926895441130:web:a5d8481db9eba8e5528393",
  measurementId: "G-JFNHBYDK9D"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);

/* document.addEventListener('DOMContentLoaded', () => {
  // TODO: Implement getParameterByName()

  // Get the action to complete.
  const mode = getParameterByName('mode');
  // Get the one-time code from the query parameter.
  const actionCode = getParameterByName('oobCode');
  // (Optional) Get the continue URL from the query parameter if available.
  const continueUrl = getParameterByName('continueUrl');
  // (Optional) Get the language code if available.
  const lang = getParameterByName('lang') || 'en';


  const app = initializeApp(firebaseConfig);
  const auth = getAuth(app);

  // Handle the user management action.
  switch (mode) {
    case 'resetPassword':
      // Mostrar el controlador de contraseña de restablecimiento y la interfaz de usuario.
      handleResetPassword(auth, actionCode, continueUrl, lang);
      break;
    case 'recoverEmail':
      // Display email recovery handler and UI.
      handleRecoverEmail(auth, actionCode, lang);
      break;
    case 'verifyEmail':
      // Display email verification handler and UI.
      handleVerifyEmail(auth, actionCode, continueUrl, lang);
      break;
    default:
      // Error: invalid mode.
  }
}, false); */



export default app;