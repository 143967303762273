export default class Textos extends String {

    // Invierte toda la cadena de String (de "este orden" a "nedro etse")
    /* getReverse(){
        return this.toString().split(''),reverse().join('');
    } */

    // Para el estado de Usuario Verificado
    usuarioVerificado() {

        const mensaje = `Esta cuenta ha comprobado que pertenece a alguien con influencia en internet (o en la vida real)`;
        return mensaje;
    }

}