import React from 'react';
import { Link } from 'react-router-dom';

import '../../global.css';

import './style.css';
// import logoPagina from '../../imagenes/iconos/icon_agendapdf_512px.png';
import logoFacebook from '../../imagenes/iconos/icon-facebook.svg';
// import logoInstagram from '../../imagenes/iconos/icon-instagram.svg';
import logoLinkedin from '../../imagenes/iconos/icon-linkedin.svg';
import logoTwitter from '../../imagenes/iconos/icon-twitter.svg';


class Footer extends React.Component {
    render(){
        return(

            <footer className="footer">
                <div className="footer__container">

                    {/* Sección superior: Microsoft Store, Education, Business, Company */}
                    <div className="footer__top">

                        {/* AgendaPDF */}
                        <div className="company">
                            <h3 className="company__logo">AgendaPDF</h3>
                            <p className="company__description">
                                AgendaPDF es una creación de Fortín Tech.<br/>
                                <br/>
                                Para propuestas de trabajo o de negocio, ponte en contacto con el desarrollador.
                            </p>
                            <div className="footer__list-icon">
                                <a className="footer__list-link" href="https://facebook.com/apeordi" target='_blank' rel='noreferrer'><img className="icon-footer" src={logoFacebook} alt="Logo"/></a>                                
                                <a className="footer__list-link" href="https://linkedin.com/in/apeordi" target='_blank' rel='noreferrer'><img className="icon-footer" src={logoLinkedin} alt="Logo"/></a>
                                <a className="footer__list-link" href="https://twitter.com/intent/user?screen_name=apeordi" target='_blank' rel='noreferrer'><img className="icon-footer" src={logoTwitter} alt="Logo"/></a>
                            </div>
                            
                            <span className="copyright">&copy; 2022-2023 AgendaPDF. All Rights Reserved.</span>
                            {/* Divider */}
                            <hr className="footer__divider" />
                        </div>

                        {/* Experiencia */}
                        <div className="footer__column">
                            <h6 className="footer__title">Conócenos</h6>
                            <div className="footer__list">
                                <Link to="/acerca-de" className="footer__list-link">Acerca de</Link>                                
                                <Link to="/soluciones" className="footer__list-link">Soluciones</Link>
                                <Link to="/privacidad" className="footer__list-link">Privacidad</Link>
                                <Link to="/fortin-tech" className="footer__list-link">Fortín Tech</Link>
                            </div>
                        </div>

                        {/* Negocios */}
                        <div className="footer__column">
                            <h6 className="footer__title">Business</h6>
                            <div className="footer__list">
                                <Link to="/contacto" className="footer__list-link">Contacto</Link>
                                <Link to="/propuestas" className="footer__list-link">Propuestas</Link>
                                <Link to="/blog" className="footer__list-link">Blog</Link>
                                <Link to="/tutoriales" className="footer__list-link">Tutoriales</Link>
                            </div>
                        </div>

                        {/* Compañía */}
                        <div className="footer__column">
                            <h6 className="footer__title">Presencia</h6>
                            <div className="footer__list">
                                <Link to="/entrevistas" className="footer__list-link">Otros proyectos</Link>
                                <Link to="/menciones" className="footer__list-link">Menciones</Link>
                                <Link to="/presencia-nacional" className="footer__list-link">Presencia nacional</Link>
                                <Link to="/presencia-internacional" className="footer__list-link">Presencia internacional</Link>
                            </div>
                        </div>

                    </div>

                </div>
            </footer>

        );
    }
}


export default Footer;