import React from 'react'
import { Link } from 'react-router-dom';

import '../../global.css';
import '.././estilos/BadgeMain.css';

import imagenPrincipal from '../../imagenes/inicio-door-hub.svg'

const imagenComoAyudaAgendaPDF = "https://images.unsplash.com/photo-1564846824194-346b7871b855?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80";
const imagenSuscripcion = "https://images.unsplash.com/photo-1519336305162-4b6ed6b6fc83?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80";
const imagenComoProtegeMisDatos = "https://images.unsplash.com/photo-1578574577315-3fbeb0cecdc2?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1172&q=80";
const imagenUsabilidad = "https://images.unsplash.com/photo-1484807352052-23338990c6c6?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80";



class Inicio extends React.Component{

    render() {
        return (


            <React.Fragment>

                {/* Portada */}
                <div className='BadgeMain__container'>
                    
                    <div className='header-media-pantalla'>
                        <h1 className='BadgeMain__fuente-portada-titulo'>Agenda PDF</h1> 
                        <h2 className='BadgeMain__fuente-portada-descripcion'>Crea tareas e imprime una agenda... sí, en PDF</h2>
                        <span className='BadgeMain__textos'>Tacha, raya o guarda en papel tu día a día.</span><br/>
                        <span className='BadgeMain__textos'>Ideal para equipos grandes y pequeños.</span>
                        
                    </div>
                    
                    <div className="header-media-pantalla">
                        <img className="header-pantalla-completa" src={imagenPrincipal} alt="Imagen de inicio" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '65%', width: '65%', padding: '0 0 0 0' }}/>
                    </div>

                </div>

                {/* Botones principales */}
                <div className="header-pantalla-completa">

                        <Link to="/crear-cuenta" className="btn btn-inicio" >Crear cuenta</Link>

                        <Link to="/iniciar-sesion" className="btn btn-inicio" >Iniciar sesión 🔐</Link>

                </div>

                {/* Sección principal */}
                <div className="header-pantalla-completa">

                    <div className="header-media-pantalla">
                        <img className="imagen-media-pantalla" src={imagenComoAyudaAgendaPDF} alt="Imagen de Unsplash" style={{ aspectRatio: '1', height: '100%', width: '100%', borderRadius:'130px', padding:'80px', objectFit: 'cover' }} />
                    </div>
                    
                    <div className="header-media-pantalla-blanca">
                        <h3>¿Cómo me ayuda AgendaPDF a mejorar mi productividad?</h3>
                        <span className="span-texto-normal">Agrega tus tareas pendientes en un cómodo calendario e imprime tu itinerario diario.</span><br/>
                        <br/>
                        <span className="span-texto-normal"><del>Tacha</del>, <u>subraya</u> o rompe las hojas cuando completes tu día.</span><br/>
                        <br/>
                        <span className="span-texto-normal">Agrega a personas de tu trabajo para crear tareas colaborativas y que nadie se pierda de nada.</span><br/>
                        <br/>
                        <span className="span-texto-normal">Además, puedes incluir enlaces a tus redes sociales o correo electrónico en tu perfil.</span><br/>
                        <br/>
                    </div>
                    
                </div>

                {/* Sección principal */}
                <div className="header-pantalla-completa">
                    
                    <div className="header-media-pantalla-blanca">
                        <h3>¿Cuánto cuesta la suscripción?</h3>
                        <span className="span-texto-normal"><b>NADA</b>.</span><br/>
                        <br/>
                        <span className="span-texto-normal">Todas las funciones son totalmente GRATIS.</span><br/>
                        <br/>
                        <span className="span-texto-normal">No hay límites de uso ni "funciones premium" escondidas.</span><br/>
                        <br/>
                    </div>

                    <div className="header-media-pantalla">
                        <img className="imagen-media-pantalla" src={imagenSuscripcion} alt="Imagen de Unsplash" style={{ height: '100%', width: '100%', borderRadius:'100px', padding:'50px' }} />
                    </div>
                    
                </div>

                {/* Sección principal */}
                <div className="header-pantalla-completa">

                    <div className="header-media-pantalla">
                        <img className="imagen-media-pantalla" src={imagenComoProtegeMisDatos} alt="Imagen de Unsplash" style={{ height: '100%', width: '100%', borderRadius:'100px', padding:'50px' }} />
                    </div>
                    
                    <div className="header-media-pantalla-blanca">
                        <h3>¿Cómo protegen mis datos?</h3>
                        <span className="span-texto-normal">Tenemos una política de privacidad bastante estricta. No vendemos ni recopilamos tus datos con fines comerciales.</span><br/>
                        <br/>
                        <span className="span-texto-normal">• Por eso no trabajamos con anuncios ni socios externos.</span><br/>
                        <br/>
                        <span className="span-texto-normal">Si te preocupan tus datos, siempre puedes leer nuestra política de <Link to="/privacidad">Privacidad</Link> 🔐</span><br/>
                        <br/>
                    </div>
                    
                </div>

                {/* Sección principal */}
                <div className="header-pantalla-completa">
                    
                    <div className="header-media-pantalla-blanca">
                        <h3>¿Dónde puedo usar AgendaPDF?</h3>
                        <span className="span-texto-normal">Actualmente AgendaPDF está disponible como Página Web, hay una app para Android en desarrollo.</span><br/>
                        <br/>
                        <span className="span-texto-normal">Accede desde cualquier parte del mundo a tu agenda e imprime donde quieras.</span><br/>
                        <br/>
                        <span className="span-texto-normal">Más funciones pronto ✨</span><br/>
                        <br/>
                    </div>

                    <div className="header-media-pantalla">
                        <img className="imagen-media-pantalla" src={imagenUsabilidad} alt="Imagen de Unsplash" style={{ height: '100%', width: '100%', borderRadius:'100px', padding:'50px' }} />
                    </div>
                    
                </div>


            </React.Fragment>


        );
    }
}


export default Inicio;